import React from 'react'
import Drawer from 'rc-drawer'
import 'rc-drawer/assets/index.css'
import { connect } from 'react-redux'
import { bookingProdID } from '@config/constants'
import Icons from '@components/icons'
import './quick-view.scss'
import TimeLine from './timeline'
import ContactCard from './contact-card'
import ServiceCard from './service-card'
import HistoryCard from './history-card'
import CustomFieldsCard from './customFields-card'
import _ from 'lodash'

const bookingStatus = {
  canceled: 'canceled',
  pending: 'pending',
  confirmed: 'confirmed'
}

const mapStateToProps = ({ auth, booking, accountsettings, location, payment }) => {
  return {
    ...auth,
    ...booking,
    ...accountsettings,
    ...location,
    ...payment
  }
}

const mapDispatchToProps = ({ auth, booking, accountsettings, location, payment }) => {
  return {
    ...auth,
    ...booking,
    ...accountsettings,
    ...location,
    ...payment
  }
}

class QuickView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  async componentDidMount() {
    const businessProd = { prod_id: bookingProdID }
    if (_.get(this.props, 'me.features.customfields')) {
      await this.props.getCustomFieldsProd(businessProd)
    }
  }

  async openDrawer({ booking_id: bookingId, event }) {
    await this.props.localUpdateBookingDetails({ data: event })
    await this.setState({
      open: true,
      booking_id: bookingId,
      loading: true,
      serviceloading: !(event && event.status),
      historyloading: true
    })
    if (this.props.is_enterprise) {
      const parentUser = event.parent_user ? event.parent_user._id : null
      this.setState({ customers: parentUser })
      this.props.getPredefinedTemplate({ id: parentUser, query: { customers: parentUser } })
    }
    await Promise.all([
      new Promise(async (resolve, reject) => {
        await this.props.getBookingDetails(bookingId)
        this.setState({ serviceloading: false })
        resolve()
      }),
      new Promise(async (resolve, reject) => {
        await this.props.getBookingHistory(bookingId)
        this.setState({ historyloading: false })
        resolve()
      })
    ])

    // get business time zone
    if (this.props.bookingData && this.props.bookingData.parent_user && this.props.is_enterprise) {
      await this.props.getLocationAccountSettings({ _id: this.props.bookingData.parent_user._id })
      if (this.props.userAccSettings && this.props.userAccSettings.userTimeZone) {
        this.setState({ userTimeZone: this.props.userAccSettings.userTimeZone })
      }
    } else {
      if (this.props.accountsettings && this.props.accountsettings.userTimeZone) {
        this.setState({ userTimeZone: this.props.accountsettings.userTimeZone })
      }
    }
    this.setState({ loading: false })
  }

  closeDrawer() {
    this.props.clearInvoiceData()
    this.setState({ open: false })
  }

  render() {
    const { bookingData = {} } = this.props

    const userTimeZone =
      (this.props.accountsettings && this.props.accountsettings.userTimeZone) ||
      (this.props.userAccSettings && this.props.userAccSettings.userTimeZone)

    return (
      <Drawer
        open={this.state.open}
        level={null}
        placement="right"
        // handleChild={false}
        handler={false}
        onClose={this.closeDrawer.bind(this)}
        keyboard={true}
        className="hide-mask-bg-color">
        <div className="quick-view py-20 py-sm-15 w-100">
          <header
            className="quick-view-header px-20 px-sm-30 d-flex align-items-center mb-20 pb-20 border-bottom"
            onClick={this.closeDrawer.bind(this)}>
            <i className="d-flex mr-10 icon-drawer-close">
              <Icons name="close" />
            </i>
            <span>esc</span>
          </header>
          {bookingData && bookingData.status !== bookingStatus.canceled ? (
            <TimeLine
              _loading={this.state.serviceloading}
              is_enterprise={this.props.is_enterprise}
              onBookingUpdate={this.props.onBookingUpdate}
              isMounted={this.state.open}
              permissions={this.props.permissions}
            />
          ) : null}
          <ContactCard
            _loading={this.state.serviceloading}
            is_enterprise={this.props.is_enterprise}
            customersIds={this.state.customers}
            permissions={this.props.permissions}
          />
          <ServiceCard
            _loading={this.state.serviceloading}
            customFields={this.props.customFields}
            is_enterprise={this.props.is_enterprise}
            onBookingUpdate={this.props.onBookingUpdate}
            getInvoiceData={this.props.getInvoiceData}
            userTimeZone={this.state.userTimeZone || userTimeZone}
            permissions={this.props.permissions}
          />
          {this.props.bookingData &&
          this.props.bookingData.custom_fields &&
          this.props.bookingData.custom_fields.length > 0 ? (
            <CustomFieldsCard bookingData={this.props.bookingData} />
          ) : null}
          <HistoryCard
            _loading={this.state.historyloading}
            bookingHistory={this.props.bookingHistory}
            is_enterprise={this.props.is_enterprise}
            user={this.props.user}
            bookingData={this.props.bookingData}
          />
        </div>
      </Drawer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(QuickView)
