import React from 'react'
import { History } from 'history'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { getCookie } from '@helpers/utility'
import { USER_ROLES } from '@helpers/constants'

// routes
import iconRoute from '@containers/icons/route'
import authRoutes from '@containers/auth/route'
import contactRoutes from '@containers/contacts-hub/route'
import homeRoutes from '@containers/home/route'
import reviewRoutes from '@containers/reviews/route'
import servicesRoutes from '@containers/services/route'
import bookingRoutes from '@containers/booking/route'
import paymentRoute from '@containers/payment/route'
import estimateRoute from '@containers/estimate/route'
import insuranceRoute from '@containers/insurance/route'
import managebusinessRoute from '@containers/manage-my-business/route'
import placementRoute from '@containers/placement/route'
import sitesRoute from '@containers/sites/route'
import messenger from '@containers/messenger/route'
import engagement from '@containers/engagement/route'
import myaccount from '@containers/myaccount/route'
import { routes as onboardingV4 } from '@containers/onboarding-v4/route'
import selfService from '@containers/selfservice/route'
import instantSites from '@containers/instant-sites/route'
import { routes as checkout } from '@containers/checkout/route'
import upgradeRoutes from '@containers/upgrade/route'
import reactivateRoutes from '@containers/reactivate/route'

import enterpriseHome from '@containers/enterprise/home/route'
import enterpriseMyAccount from '@containers/enterprise/myaccount/route'
import enterpriseContact from '@containers/enterprise/contact-hub/route'
import locationRoutes from '@containers/enterprise/location/route'
import settingsRoutes from '@containers/enterprise/settings/route'
import reportingRoute from '@containers/enterprise/reporting/route'
import enterpriseReviews from '@containers/enterprise/reviews/route'
import enterprisePlacements from '@containers/enterprise/placement/route'
import enterpriseManagers from '@containers/enterprise/managers/route'
import enterpriseMessenger from '@containers/enterprise/messenger/route'
import enterpriseWidget from '@containers/enterprise/widgets/route'

interface RouterRoutesProps {
  history: History
}

const RouterRoutes = ({ history }: RouterRoutesProps) => {
  const cookie = getCookie()
  const { READ_ONLY, TRIAL } = USER_ROLES
  return (
    <Router history={history}>
      <Switch>
        {[
          ...iconRoute,
          ...authRoutes,
          ...homeRoutes,
          ...reviewRoutes,
          ...contactRoutes,
          ...servicesRoutes,
          ...bookingRoutes,
          ...paymentRoute,
          ...managebusinessRoute,
          ...placementRoute,
          ...sitesRoute,
          ...messenger,
          ...engagement,
          ...myaccount,
          ...onboardingV4,
          ...selfService,
          ...enterpriseHome,
          ...enterpriseMyAccount,
          ...enterpriseContact,
          ...locationRoutes,
          ...settingsRoutes,
          ...reportingRoute,
          ...enterpriseReviews,
          ...enterprisePlacements,
          ...enterpriseManagers,
          ...enterpriseMessenger,
          ...enterpriseWidget,
          ...instantSites,
          ...checkout,
          ...upgradeRoutes,
          ...reactivateRoutes,
          ...estimateRoute,
          ...insuranceRoute
        ]}
        {/* USE TO CHECK INVALID ROUTE REDIRECTION TO HOME OR LOGIN PAGE BASED ON COOKIE */}
        <Route path="">
          {cookie && cookie.token && cookie.user_unique_id && (cookie.role === TRIAL || cookie.role === READ_ONLY) ? (
            <Redirect
              to={{
                pathname: `/${cookie.user_unique_id}/trial/home`
              }}
            />
          ) : cookie && cookie.token && cookie.user_unique_id ? (
            <Redirect
              to={{
                pathname: `/${cookie.user_unique_id}/home`
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/login'
              }}
            />
          )}
        </Route>
      </Switch>
    </Router>
  )
}

export default RouterRoutes
