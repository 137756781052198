import React, { useEffect, useState } from 'react'
import Header from '../header'
import Footer from '../footer'
import SideBar from '../sidebar'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { segmentIdentify } from '@helpers/segment'
import config from '@config/index'
import Loader from '../loader'
import { connectWS } from '../websocket'
import { getCookie, isEnabled, IsJsonString, isMultiLocation } from '@helpers/utility'
import QuickbookThanks from '@containers/payment/utility/quickbook-thanks-popup'
// import BrandingUpdateModal from "../brandingUpdateModal"
import { intercomUpdate } from '@helpers/index'
import _ from 'lodash'

const mapDispatchToProps = ({ auth, accountsettings, common, messenger, myaccount }) => {
  return {
    ...auth,
    ...accountsettings,
    ...common,
    ...messenger,
    ...myaccount
  }
}
const mapStateToProps = ({ auth, accountsettings, common, messenger, myaccount }) => {
  return {
    ...auth,
    ...accountsettings,
    ...common,
    ...messenger,
    ...myaccount
  }
}

const Layout = (props) => {
  const [showUpgradeTrial, setShowUpgradeTrial] = useState(false)
  const [hubSpotLoaded, setHubSpotLoaded] = useState(false)
  const [quickbookEvent, setQuickbookEvent] = useState(false)
  const [thumbtackEvent, setThumbtackEvent] = useState(false)
  const [menuOpen, setMenuOpen] = useState(true)
  const [showThanksModal, setShowThanksModal] = useState(false)
  const [showBento, setShowBento] = useState(false)

  const getNotificationCount = async () => {
    const payload = { role: props.me.role_id.role_name }
    if (props.me.role_id.role_name === 'partner') {
      const activity = [
        'new_contact_fail',
        'google_sync_failed',
        'google_sync_success',
        'user_import_success',
        'user_import_failed'
      ]
      payload.activity = activity
    } else if (['admin', 'superadmin'].indexOf(props.me.role_id.role_name)) {
      const activity = [
        'new_contact_fail',
        'google_sync_failed',
        'google_sync_success',
        'user_import_success',
        'user_import_failed',
        'mid_missing',
        'cardconnect_settings_updated',
        'cardconnect_settings_update_failed',
        'scrape_process_complete',
        'twilio_number_request',
        'hubspot_crm_status'
      ]
      payload.activity = activity
    }
    await props.getNotificationCount(payload)
  }
  const loadHubspot = () => {
    if (props.user && props.user.role === 'trial') {
      const hubspotJs = document.createElement('script')
      hubspotJs.type = 'text/javascript'
      hubspotJs.id = 'hs-script-loader'
      hubspotJs.src = `//js.hs-scripts.com/${config.hbId}.js`
      document.body.appendChild(hubspotJs)
      setHubSpotLoaded(true)
    }
  }

  useEffect(() => {
    // upgrade to pro plan
    window.addEventListener('message', receiveMessage)

    const asyncMount = async () => {
      const innerMe = props.me
      const cookies = getCookie()

      if (!props.domainSettings) await props.validateDomain()

      if (!props.hasMeValue) {
        props.updateHasMeValue()
        if (props.me && props.me._id) {
          if (
            props.products &&
            isEnabled('messenger', props.products) &&
            props.user.role &&
            props.user.role !== 'partner' &&
            props.user.role !== 'manager'
          ) {
            if (!props.ws || localStorage.getItem('wsDisconnected') === 'true') {
              if (localStorage.getItem('wsDisconnected') === 'true') localStorage.removeItem('wsDisconnected')
              await connectWS(props.me).then((ws) => subscribeChannel(ws))
            } else {
              props.ws.addEventListener('message', (e) => updateMessageCount(e))
              props.ws.addEventListener('close', (e) => onCloseSocket(e))
            }
          }
          // segment Identify
          segmentIdentify(props.me, props.role)

          await props.fetchAccountSettings({ _id: props.me._id })
          getNotificationCount()
          if (props.user && props.user.role === 'trial' && !hubSpotLoaded && !window._hsq) {
            await loadHubspot()
            const _hsq = (window._hsq = window._hsq || [])
            _hsq.push(['identify', { email: props.me.user_email }])
          }
        }
        if (!props.predefinedTemplates && props.me && props.me._id) {
          await props.getPredefinedTemplate({ id: props.me._id })
        }
      }
      if (props.me) {
        intercomUpdate(props.me)
      }

      /** Flags for multi location */
      const flagStatus = _.get(innerMe, 'features.upgradeTrial', undefined)
      const flagStatusForMultiLocation = _.get(innerMe, 'features.upgradeToProMultiLocation', undefined)
      // check if user has completed all Bento steps - if so hide it
      const showBento = _.get(innerMe, 'features.showBento', undefined)

      setShowBento(showBento)

      let subScribed = false
      const chargeBeeData = props.me.chargeBeeData || (await props.getChargebeeSubscription())
      if (chargeBeeData) {
        if (
          (chargeBeeData.chargebee_account_id && chargeBeeData.chargebee_subscription_id) ||
          chargeBeeData.chargebee_not_required
        ) {
          subScribed = true
        }
      }
      const platformId = _.get(props, 'me.platform_id', config.platformID)
      const isPartnerUser = platformId !== config.platformID

      let showUpgradeTrial = false
      if (innerMe.isMultiLocation || cookies.businessProfile) {
        showUpgradeTrial =
          flagStatusForMultiLocation && !subScribed && innerMe && !innerMe.offline_payment && !cookies.businessProfile
      } else {
        showUpgradeTrial =
          flagStatus && !subScribed && innerMe && !innerMe.offline_payment && !isMultiLocation() && !isPartnerUser
      }

      setShowUpgradeTrial(showUpgradeTrial)
    }

    if (props.me && props.me._id) {
      asyncMount()
    }

    return function cleanup () {
      window.removeEventListener('message', receiveMessage)
    }
  }, [props.me])

  const subscribeChannel = async (ws) => {
    await props.updateWebsocket(ws)
    const data = {
      channelId: props.me._id,
      globalId: props.me._id,
      userId: props.me._id
    }

    ws.send(
      JSON.stringify({
        action: 'subscribeChannel',
        data: data
      })
    )

    ws.send(
      JSON.stringify({
        action: 'listGlobalHistory',
        data: {
          globalId: props.me._id
        }
      })
    )

    ws.send(
      JSON.stringify({
        action: 'listLiveUsers',
        data: {
          globalId: props.me._id
        }
      })
    )

    ws.addEventListener('message', (e) => updateMessageCount(e))
    ws.addEventListener('close', (e) => onCloseSocket(e))
  }

  const onCloseSocket = async (e) => {
    if (localStorage.getItem('wsDisconnected') === 'true' && e.reason === 'Going away') {
      // todo reconnect here
      console.log('reconnect here')
      if (localStorage.getItem('wsDisconnected') === 'true') localStorage.removeItem('wsDisconnected')
      await connectWS(props.me).then((ws) => subscribeChannel(ws))
    }
  }
  const updateMessageCount = (e) => {
    var payload = JSON.parse(e.data)
    if (payload.type === 'awsPushNotification') {
      var result = payload.data
      if (result.meta.type === 'messenger' && !result.meta.data) {
        if (!window.location.href.includes('/messenger')) {
          if (result.sender_id && result.sender_id !== props.me._id) {
            props && props.updateMessageLayoutCount(true)
          } else if (!result.sender_id) {
            props && props.updateMessageLayoutCount(true)
          }
        }
      } else if (
        result.meta.type === 'messenger' &&
        result.meta.data &&
        result.meta.data.hasOwnProperty('isThumbastackLead')
      ) {
        if (!window.location.href.includes('/messenger')) {
          if (result.sender_id && result.sender_id !== props.me._id) {
            props && props.updateMessageLayoutCount(true) // to show new tag in side menu for thumbtack messages
          } else if (!result.sender_id) {
            props && props.updateMessageLayoutCount(true)
          }
        }
      }
    } else if (payload.type === 'listGlobalHistory') {
      const unreadMessages = unreadCount(payload.data.Items)
      if (unreadMessages.length && !window.location.href.includes('/messenger')) {
        props.updateMessageLayoutCount(true)
      }
      props && props.updateMessagelistGlobalHistory(payload.data)
    } else if (payload.type === 'typingStarted' || payload.type === 'typingEnd') {
      props && props.updateTypingStatus(payload)
    } else if (payload.type === 'liveUsers') {
      props && props.handleWSPayloadSuccess(payload)
    } else if (payload.type === 'endUserRegister') {
      props && props.handleWSPayloadSuccess(payload)
    }
  }

  const unreadCount = (m) => {
    return m.filter(function (message) {
      if (!props.me.messenger_view_time) {
        return true
      }
      return message.createdAt > props.me.messenger_view_time
    })
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }
  const receiveMessage = async (event) => {
    if (event && event.data) {
      const data = IsJsonString(event.data) ? JSON.parse(event.data) : {}
      if (
        data &&
        data &&
        data.code &&
        !quickbookEvent &&
        !data.isThumbtack
      ) {
        setQuickbookEvent(async () => {
          data.clientId = config.quickbooksClientId
          data.redirectUri = `${window.location.origin}/socialconnect`
          console.log(data)

          await props.quickBookAuth(data).then(async (quickbook) => {
            const payload = { ...quickbook, ...props.qbsettings }
            await props.updateQuickBookSettings(payload).then(async (quickbookData) => {
              if (quickbookData) {
                setShowThanksModal(true)
                await props.fetchAccountSettings({ _id: props.me._id })
              }
            })
          })
          return false
        })
      }

      if (
        data &&
        data.code &&
        data.isThumbtack &&
        !thumbtackEvent
        // && (window.location.pathname.indexOf('app-source-flow') > -1)
      ) {
        setThumbtackEvent(async () => {
          data.clientId = config.thumbtackClientId
          data.redirectUri = `${window.location.origin}/thumbtack/redirect`
          console.log(data)

          await props.thumbtackAuth(data).then(async (thumbtack) => {
            const payload = { ...thumbtack, ...props.qbsettings }
            await props.updateThumbtackSettings(payload).then(async (thumbtackData) => {
              if (thumbtackData) {
                await props.fetchAccountSettings({ _id: props.me._id })
              }
            })
          })
          return false
        })
      }
    }
  }
  const closeThnaksModal = () => {
    setShowThanksModal(false)
  }

  const { children, settings } = props
  const cookies = getCookie()

  return (
    <div className={`app-body overlay-loader-outer ${menuOpen ? '' : 'menu-off'}`}>
      {showBento && <bento-sidebar />}
      {!settings.hideHeader && props.me && props.me._id && !cookies.hideNav ? (
        <Header
          showUpgradeTrial={showUpgradeTrial}
          page={settings.page}
          getNotificationCount={getNotificationCount}
          onMinimise={toggleMenu}
        />
      ) : null}

      {!settings.hideHeader ? (
        <React.Fragment>
          {props.me && props.me._id ? (
            <div
              className={`${
                cookies.hideNav ? 'main-wrapper-with-only-min-height' : 'main-wrapper'
              } w-100 d-flex position-relative ${showUpgradeTrial ? 'main-wrapper-top-space-up' : ''}`}>
              <React.Fragment>
                {!cookies.hideNav ? (
                  <SideBar className="master-sidebar" page={settings.page} setTabRedirect={props.setTabRedirect} showUpgradeTrial={showUpgradeTrial} />
                ) : null}
                <main className="main mw-100 flex-grow-1">{children}</main>
              </React.Fragment>
            </div>
          ) : (
            <div className="full-page-loader">
              <Loader />
            </div>
          )}
        </React.Fragment>
      ) : null}

      {!settings.hideFooter && props.me && props.me._id && !cookies.hideNav ? <Footer /> : null}
      {showThanksModal ? (
        <QuickbookThanks
          close={closeThnaksModal}
          quickbookData={props.quickbookData}
        />
      ) : null}
    </div>
  )
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
