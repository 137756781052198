import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Icon from '@components/icons'
import Tooltip from '@components/tooltip'
import ConfirmBookingPopup from '../confirm-booking-popup'
import SendInvoicePopup from '@containers/payment/utility/send-invoice-popup'
import SendInvoiceConfirmPopup from '@containers/payment/utility/send-invoice-confirm-popup'
import { segmentEventTrack } from '@helpers/segment'
import { phoneNumberFormat, isDisabled } from '@helpers/utility'
import ResendPopup from '@containers/payment/utility/resendPopup'
import { withTranslation } from 'react-i18next'

const timelineLoader = [1].map((l) => {
  return (
    <div key={l}>
      <div className="d-flex align-items-center mb-20">
        <div className="placeholder-loader w-100 py-15 mr-15"></div>
        <div className="placeholder-loader btn-target border-0"></div>
      </div>
      <div className="d-flex align-items-center">
        <div className="placeholder-loader w-50 py-15 mr-15"></div>
        <div className="placeholder-loader w-50 py-15"></div>
      </div>
    </div>
  )
})

const bookingStatus = {
  canceled: 'canceled',
  pending: 'pending',
  confirmed: 'confirmed'
}

const mapStateToProps = ({ auth, booking, payment, contact }) => {
  return {
    ...auth,
    ...booking,
    ...payment,
    ...contact
  }
}

const mapDispatchToProps = ({ auth, booking, payment, contact }) => {
  return {
    ...auth,
    ...booking,
    ...payment,
    ...contact
  }
}

class TimeLine extends React.Component {
  state = {
    loading: false,
    showInvoiceModal: false,
    showInvoiceSendTypeModal: false,
    isMounted: false,
    stepRender: false
  }

  componentDidMount() {
    this.init()
  }
  componentDidUpdate(prevProps) {
    if (this.props.bookingData && this.props.bookingData._id) {
      if (
        !prevProps.bookingData ||
        !prevProps.bookingData._id ||
        this.props.bookingData._id !== prevProps.bookingData._id
      ) {
        this.init()
      } else {
        if (this.props.isMounted && this.props.isMounted !== prevProps.isMounted) {
          this.setState({ isMounted: true })
          setTimeout(() => {
            this.setState({ stepRender: true })
          }, 3000)
        }
      }
    }
    if (!this.props.isMounted && this.props.isMounted !== prevProps.isMounted) {
      this.setState({ isMounted: false, stepRender: false })
    }
  }

  async init() {
    this.setState({ isMounted: true })
    setTimeout(() => {
      this.setState({ stepRender: true })
    }, 3000)
    // await this.props.getInvoiceData(this.props.bookingData && this.props.bookingData.invoice_id ? this.props.bookingData.invoice_id : this.props.bookingData._id)
    this.setState({ loading: false })
  }

  async confirmPopupShow() {
    const res = await this.ConfirmBookingPopup.showPopup()
    if (res) {
      await this.props.confirmBooking({ appId: [this.props.bookingData._id] })
      this.props.getBookingDetails(this.props.bookingData._id)
      this.props.getBookingHistory(this.props.bookingData._id)
      if (this.props.onBookingUpdate) this.props.onBookingUpdate()
    }
  }

  getStartLabel(date) {
    return this.props.t('bookings.starts-moment-date', { 0: moment(date.replace('Z', '')).fromNow() })
  }
  async viewInvoicePopupShow() {
    await this.props.getInvoiceData(
      this.props.bookingData && this.props.bookingData.invoice_id
        ? this.props.bookingData.invoice_id
        : this.props.bookingData._id
    )
    if (this.props.invoice) {
      const invoice = this.props.invoice
      if (invoice && invoice.customer_email) invoice.send_email = true
      if (invoice && invoice.customer_phone) invoice.send_sms = true
      if (invoice.gd_invoice_items && invoice.gd_invoice_items.length) {
        const invocieItem = [
          {
            ...invoice.gd_invoice_items[0],
            service_price_total: invoice.invoice_amount,
            service_price: invoice.invoice_amount
          }
        ]
        invoice.invoice_items = invocieItem
      }
      this.setState({ invoice, showInvoiceModal: true })
    }
  }
  closeInvoiceModal(status = false) {
    if (status && !this.state.showInvoiceSendTypeModal) this.trackAbandentPayments('abandon_payment_invoice')
    this.setState({ showInvoiceModal: false, invoice: null })
  }
  async autoSuggestContacts(payload) {
    await this.props.autosuggestContacts(payload)
    let contactList = []
    if (this.props.searchRes) {
      this.props.searchRes.forEach((element) => {
        if (element.type === 'contact') {
          const data = {
            label: element.first_name ? `${element.first_name} ${element.last_name ? element.last_name : ''}` : '',
            subLabel: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
            value: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
            type: 'contact',
            image: element.image ? element.image : '',
            contactObj: element
          }
          contactList = [...contactList, data]
        }
      })
      this.setState({ contactList: contactList })
    }
  }
  async autoSuggestServices(payload) {
    await this.props.getServices(payload)
    let servicesList = []
    if (this.props.services) {
      this.props.services.forEach((element) => {
        const data = {
          label: element.title ? element.title : '',
          subLabel: element.price ? element.price : '',
          value: element._id
        }
        servicesList = [...servicesList, data]
      })
      this.setState({ servicesList: servicesList })
    }
  }
  async savePrivateContact(payload) {
    await this.props.savePrivateContact(payload)
    if (this.props.privateContact && this.props.privateContact.data) {
      this.props.segmentConatctTrack({ contactIds: [this.props.privateContact.data._id] })
    }
    if (!this.props.apiError) {
      const segmentData = {
        event: 'contact_added',
        properties: {
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name,
          has_email: !!payload.email,
          has_phone: !!payload.mobile,
          has_address: !!(payload.address || payload.address2)
        }
      }
      segmentEventTrack(segmentData)
    }
  }
  async confirmInvoice(payload) {
    this.closeInvoiceModal()
    this.setState({ showInvoiceSendTypeModal: true, invoiceData: payload })
  }
  closeInvoiceTypeModal(status = false) {
    if (status) this.trackAbandentPayments('abandon_payment_invoice')
    this.setState({ showInvoiceSendTypeModal: false })
  }
  trackAbandentPayments(event) {
    let os = 'desktop'
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      os = 'ios'
      if (/Android/.test(navigator.userAgent)) os = 'android'
    }
    const data = {
      event: event,
      properties: {
        user_id: this.props.me && this.props.me._id,
        customer_id: this.props.me && this.props.me.company_id,
        role:
          this.props.me &&
          this.props.me.role_id &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name === 'trial'
            ? 'free-trial'
            : this.props.me.role_id.role_name,
        business_category:
          this.props.me && this.props.me.primaryCategories && this.props.me.primaryCategories.category_name,
        platform: os,
        screen: 'payments'
      }
    }
    segmentEventTrack(data)
  }
  async saveInvoiceData(payload) {
    this.setState({ invoiceSaveLoader: true })
    payload.source = 'booking'
    payload.booking_id = this.props.bookingData._id
    await this.props.saveInvoiceData(payload)
    this.props.getBookingDetails(this.props.bookingData._id)
    this.closeInvoiceTypeModal()
    this.setState({ invoiceSaveLoader: false })

    if (!payload.invoice_id) {
      let data = {}
      data = {
        event: 'invoices_sent',
        properties: {
          user_id: this.props.me._id,
          role: this.props.me.role_id.role_name,
          step: 'NewInvoice-ItemsAdded',
          id: this.props.privateContact.data._id,
          total: payload.invoice_amount,
          items: payload.invoice_items.length,
          send_type: payload.send_type
        }
      }
      if (this.props.me.primaryCategories && this.props.me.primaryCategories.category_name) {
        data.properties.business_category = this.props.me.primaryCategories.category_name
      }
      segmentEventTrack(data)
    }
  }
  async updateContact(payload) {
    await this.props.updateContact(payload)
    if (!this.props.apiError) {
      const segmentData = {
        event: 'contact_updated',
        properties: {
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name,
          has_email: !!payload.email,
          has_phone: !!payload.mobile,
          has_address: !!(payload.address || payload.address2)
        }
      }
      segmentEventTrack(segmentData)
    }
  }
  async sendReminder() {
    await this.props.getInvoiceData(
      this.props.bookingData && this.props.bookingData.invoice_id
        ? this.props.bookingData.invoice_id
        : this.props.bookingData._id
    )
    this.resendPopup.showPopup(this.props.invoice)
  }
  async handleResendRequset(item) {
    const payload = {}
    //  payload.id = item.id ? item.id : item._id;
    payload.id =
      this.props.bookingData && this.props.bookingData.payment_id ? this.props.bookingData.payment_id._id : null
    payload.params = { invoice_type: item.source ? item.source : 'booking' }
    payload.data = { send_email: item.send_email, send_sms: item.send_sms }
    await this.props.resendInvoice(payload)
  }
  isPaymentAcess() {
    let status = false
    if (!isDisabled('payment', this.props.disabledProducts)) status = true
    return status
  }

  render() {
    const { bookingData = {} } = this.props
    return (
      <div className="p-20 p-md-30 border-bottom mb-15">
        {this.props._loading || this.state.loading ? (
          <div>{timelineLoader}</div>
        ) : bookingData && bookingData._id ? (
          <>
            <div
              className={`timeline-wrapper d-flex align-items-start ${this.state.isMounted ? 'timeline-loaded' : ''}`}>
              {/* ------------------------- Section 1 starts here ----------------------------- */}
              {bookingData && bookingData.status === bookingStatus.pending ? (
                <div className="timeline-item bg-transparent active last">
                  <Tooltip type="button" position="top" message="Schedule">
                    <div className="timeline-pin">
                      <span className="timeline-dot"></span>
                    </div>
                    <div className="timeline-content">
                      <h3 className="timeline-title">Schedule</h3>
                      <div className="timeline-link cursor-pointer" onClick={this.confirmPopupShow.bind(this)}>
                        Confirm now
                      </div>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <div className="timeline-item bg-transparent active">
                  <Tooltip type="button" position="top" message="Schedule">
                    <div className="timeline-pin">
                      <Icon name="tickThinNormal" />
                    </div>
                    <div className="timeline-content">
                      <div className="timeline-icon">
                        <Icon name="calendarDate" />
                      </div>
                    </div>
                  </Tooltip>
                </div>
              )}
              {/* ------------------------- Section 1 ends here ----------------------------- */}

              {/* ------------------------- Section 2 starts here ----------------------------- */}
              {bookingData && bookingData.status === bookingStatus.pending ? (
                <div className="timeline-item bg-transparent">
                  <Tooltip type="button" position="top" message="Start">
                    <div className="timeline-pin">
                      <span className="timeline-dot"></span>
                    </div>
                    <div className="timeline-content">
                      <div className="timeline-icon">
                        <Icon name="playArrowOutline" />
                      </div>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <>
                  {bookingData.appointment_end_date &&
                  moment().isBefore(bookingData.appointment_end_date.replace('Z', '')) ? (
                    <div className="timeline-item bg-transparent active last">
                      <Tooltip type="button" position="top" message="Start">
                        <div className="timeline-pin">
                          <span className="timeline-dot"></span>
                        </div>
                        <div className="timeline-content">
                          <h3 className="timeline-title">
                            {' '}
                            {moment().isAfter(bookingData.appointment_start_date.replace('Z', '')) &&
                            moment().isBefore(bookingData.appointment_end_date.replace('Z', ''))
                              ? 'Started'
                              : 'Start'}
                          </h3>
                          {moment().isAfter(bookingData.appointment_start_date.replace('Z', '')) &&
                          moment().isBefore(bookingData.appointment_end_date.replace('Z', '')) ? null : (
                            <div className="timeline-info-text">
                              {this.getStartLabel(bookingData.appointment_start_date)}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="timeline-item bg-transparent active">
                      <Tooltip type="button" position="top" message="Start">
                        <div className="timeline-pin">
                          <Icon name="tickThinNormal" />
                        </div>
                        <div className="timeline-content">
                          <div className="timeline-icon">
                            <Icon name="playArrowOutline" />
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
              {/* ------------------------- Section 2 end here ----------------------------- */}

              {/* ------------------------- Section 3 starts here ----------------------------- */}
              {(bookingData && bookingData.status === bookingStatus.pending) ||
              (bookingData.appointment_end_date &&
                moment().isBefore(bookingData.appointment_end_date.replace('Z', '')) &&
                bookingData.payment_id &&
                bookingData.payment_id.status !== 'processing') ||
              (!bookingData.payment_id &&
                bookingData.appointment_end_date &&
                !moment().isAfter(bookingData.appointment_end_date.replace('Z', ''))) ||
              (bookingData.payment_id &&
                bookingData.payment_id.status === 'processing' &&
                bookingData.payment_id.partial_payment) ? (
                <div className="timeline-item bg-transparent">
                  <Tooltip type="button" position="top" message="Invoice">
                    <div className="timeline-pin">
                      <span className="timeline-dot"></span>
                    </div>
                    <div className="timeline-content">
                      <div className="timeline-icon">
                        <Icon name="listBordered" />
                      </div>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <>
                  {(bookingData.payment_id &&
                    bookingData.payment_id.status &&
                    bookingData.payment_id.status === 'draft' &&
                    bookingData.payment_id.source === 'booking') ||
                  (bookingData.payment_id &&
                    bookingData.payment_id.partial_payment &&
                    bookingData.payment_id.status !== 'open' &&
                    bookingData.payment_id.status !== 'due' &&
                    bookingData.payment_id.status !== 'pending' &&
                    bookingData.payment_id.status !== 'accepted' &&
                    bookingData.payment_id.status !== 'capture') ||
                  (!bookingData.payment_id &&
                    bookingData.appointment_end_date &&
                    moment().isAfter(bookingData.appointment_end_date.replace('Z', ''))) ? (
                    <div className="timeline-item bg-transparent active last">
                      <Tooltip type="button" position="top" message="Invoice">
                        <div className="timeline-pin">
                          <span className="timeline-dot"></span>
                        </div>
                        <div className="timeline-content">
                          <h3 className="timeline-title">Invoice</h3>
                          {this.isPaymentAcess() ? (
                            <div
                              className="timeline-link cursor-pointer"
                              onClick={this.viewInvoicePopupShow.bind(this)}>
                              View now
                            </div>
                          ) : null}
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div
                      className={`timeline-item bg-transparent active  ${
                        moment().isBefore(bookingData.appointment_end_date.replace('Z', '')) ? 'last' : ''
                      }`}>
                      <Tooltip type="button" position="top" message="Invoice">
                        <div className="timeline-pin">
                          <Icon name="tickThinNormal" />
                        </div>
                        <div className="timeline-content">
                          <div className="timeline-icon">
                            <Icon name="listBordered" />
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}

              {/* ------------------------- Section 3 end here ----------------------------- */}
              {/* ------------------------- Section 4 start here ----------------------------- */}

              {!this.state.stepRender ||
              (bookingData && bookingData.status === bookingStatus.pending) ||
              (bookingData.appointment_end_date &&
                moment().isBefore(bookingData.appointment_end_date.replace('Z', '')) &&
                bookingData.payment_id &&
                bookingData.payment_id.status !== 'processing') ||
              (bookingData.payment_id && bookingData.payment_id.status === 'draft') ||
              !bookingData.payment_id ||
              (bookingData.payment_id.status === 'processing' && bookingData.payment_id.partial_payment) ? (
                <div className="timeline-item bg-transparent">
                  <Tooltip type="button" position="top" message="Payment">
                    <div className="timeline-pin">
                      <span className="timeline-dot"></span>
                    </div>
                    <div className="timeline-content">
                      <div className="timeline-icon">
                        <Icon name="dollarThin" />
                      </div>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <>
                  {bookingData.payment_id &&
                  bookingData.payment_id.source === 'booking' &&
                  (bookingData.payment_id.status === 'open' ||
                    bookingData.payment_id.status === 'due' ||
                    bookingData.payment_id.status === 'pending') ? (
                    <div className="timeline-item bg-transparent active last">
                      <Tooltip type="button" position="top" message="Payment">
                        <div className="timeline-pin">
                          <span className="timeline-dot"></span>
                        </div>
                        <div className="timeline-content">
                          <h3 className="timeline-title">Payment</h3>
                          {this.isPaymentAcess() ? (
                            <div className="timeline-link cursor-pointer" onClick={this.sendReminder.bind(this)}>
                              Send reminder
                            </div>
                          ) : null}
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="timeline-item bg-transparent active last">
                      <Tooltip type="button" position="top" message="Payment">
                        <div className="timeline-pin">
                          <Icon name="tickThinNormal" />
                        </div>
                        <div className="timeline-content">
                          <div className="timeline-icon">
                            <Icon name="dollarThin" />
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
              {/* ------------------------- Section 4 end here ----------------------------- */}
            </div>
          </>
        ) : null}
        <ConfirmBookingPopup ref={(ref) => (this.ConfirmBookingPopup = ref)} />
        <ResendPopup
          ref={(ref) => (this.resendPopup = ref)}
          title="Resend Request"
          okText="YES, RESEND REQUEST"
          message="Do you want to resend the payment request?"
          handleResendRequset={this.handleResendRequset.bind(this)}
        />
        {this.state.showInvoiceModal ? (
          <SendInvoicePopup
            open={this.state.showInvoiceModal}
            closeModal={this.closeInvoiceModal.bind(this)}
            autosuggestContacts={this.autoSuggestContacts.bind(this)}
            contactList={this.state.contactList}
            me={this.props.me}
            savePrivateContact={this.savePrivateContact.bind(this)}
            privateContact={this.props.privateContact}
            confirmInvoice={this.confirmInvoice.bind(this)}
            products={this.props.products}
            autoSuggestServices={this.autoSuggestServices.bind(this)}
            servicesList={this.state.servicesList}
            invoiceItem={this.state.invoice}
            fromBooking={true}
            permissions={this.props.permissions}
            paymentStatus={this.props.onBoardingData.status}
          />
        ) : null}
        {this.state.showInvoiceSendTypeModal ? (
          <SendInvoiceConfirmPopup
            open={this.state.showInvoiceSendTypeModal}
            closeModal={this.closeInvoiceTypeModal.bind(this)}
            invoiceData={this.state.invoiceData}
            saveInvoiceData={this.saveInvoiceData.bind(this)}
            invoiceSaveLoader={this.state.invoiceSaveLoader}
            updateContact={this.updateContact.bind(this)}
            me={this.props.me}
          />
        ) : null}
      </div>
    )
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TimeLine))
