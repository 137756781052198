import React from 'react'
import { PublicRoute } from '@app/router/access-route'
import asyncComponent from '@helpers/async-func'

export default [
  <PublicRoute
    exact
    path="/icons"
    key="icons"
    layoutSettings={{ page: 'icons' }}
    component={asyncComponent(() => import('./Icons'))}
  />
]
