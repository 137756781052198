import { api, apiV2, apiV2Uberall, apiV2Gbm, catchHandler } from '@helpers/axios'
import { objToUrlParams } from '@helpers/index'
import config from '@config/index'
import { getV2ConfigCatValue } from '@helpers/utility'
import { getFeatureVal, featureFlags } from '@helpers/configcat'

export function fetchBusinessSettings(payload) {
  return api()
    .get(`${config.routes.businessSettings}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getsettings(payload) {
  return api()
    .get(`${config.routes.getsettings}?option_key=${payload}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function triggerContactSales(payload) {
  return api()
    .post(config.routes.triggerContactSales)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function updateBusinessSettings(payload) {
  let callV2flag = true
  const requestURlV2 = `${config.routes.gmbDataSyncV2}${config.routes.businessSettings}/${payload._id}`
  let requestURl = `${config.routes.businessSettings}/${payload._id}`
  if (payload && payload.type) {
    if (payload.type === 'logoUpdate') {
      const userID = payload && payload._id
      requestURl = `${config.routes.businessSettings}/${userID}/logo`
      callV2flag = false
      delete payload.type
    }
  }
  if (payload.updatetType === 'coverImageUpdate') {
    const userID = payload && payload._id
    requestURl = `${config.routes.businessSettings}/${userID}/cover`
    callV2flag = false
    delete payload.updatetType
  }
  if (payload.throwBackErr) {
    delete payload.throwBackErr
  }

  delete payload._id
  if (!requestURl.includes('cover')) delete payload.type

  const value = await getV2ConfigCatValue('BusinessSettings')
  if (value && callV2flag) {
    return apiV2()
      .put(requestURlV2, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .put(requestURl, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function fetchSummaryReports(payload) {
  return api()
    .get(`${config.routes.accountSettings}/getaccountsettings/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateSummaryReports(payload) {
  return api()
    .put(`${config.routes.accountSettings}/updateaccountsettings/${payload._id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getServiceAreas(payload) {
  const userID = payload && payload.user
  const locationId = payload && payload.location
  delete payload.user
  delete payload.location
  return api()
    .put(`${config.routes.businessSettings}/${locationId}/users/${userID}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getStaffUsers(data) {
  return api()
    .get(`${config.routes.getcustomers}?${objToUrlParams(data)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getCustomFields() {
  return api()
    .get(`${config.routes.getCustomFields}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getCustomField(fieldId) {
  return api()
    .get(`${config.routes.getCustomFields}/${fieldId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteCustomField(fieldId) {
  return api()
    .delete(`${config.routes.deleteCustomField}/${fieldId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function activities(data) {
  return api()
    .get(`${config.routes.activities}?${objToUrlParams(data)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateBusinessImage(payload) {
  const userID = payload && payload._id
  delete payload._id
  return api()
    .put(`${config.routes.businessSettings}/${userID}/logo`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getCategories(payload) {
  return api()
    .get(`${config.routes.businessSettings}/categories?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getOnboardingCategories(payload) {
  return api()
    .get(`${config.routes.businessSettings}/onBoardingcategories?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function updateMediaPhotoName(payload) {
  const userID = payload._id
  const data = { description: payload.description }
  if (!payload.id) data.url = payload.url

  return api()
    .put(`${config.routes.businessSettings}/${userID}/photo/${payload.id}`, data)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function deleteMediaPhoto(payload) {
  const userID = payload._id
  const data = {}
  if (!payload.id) data.url = payload.url

  return api()
    .delete(`${config.routes.businessSettings}/${userID}/photo/${payload.id}`, { data })
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function deleteMediaVideo(payload) {
  const userID = payload._id
  const value = await getV2ConfigCatValue('BusinessSettings')
  if (value) {
    return apiV2()
      .put(`${config.routes.gmbDataSyncV2}/${config.routes.businessSettings}/${userID}`, payload.videoUrls)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .put(`${config.routes.businessSettings}/${userID}`, payload.videoUrls)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function updateStaffStatus(payload) {
  const userID = payload._id
  delete payload._id
  return api()
    .put(`${config.routes.updateStaffStatus}/${userID}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function connectFacebook(payload) {
  return api()
    .get(`${config.facebook_graph_url}accounts?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export async function facebookSync(payload) {
  const value = await getV2ConfigCatValue('FB')
  if (value) {
    return apiV2()
      .post(`${config.routes.facebookDataSyncV2}/user-sync-facebook`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(`${config.routes.businessSettings}/user-sync-facebook`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function getSocialConnections(payload) {
  return api()
    .get(`${config.routes.businessSettings}/${payload.id}/social-connections`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function facebookUnSync(payload) {
  const value = await getV2ConfigCatValue('FB')
  if (value) {
    return apiV2()
      .post(`${config.routes.facebookDataSyncV2}/user-unsync-facebook`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(`${config.routes.businessSettings}/user-unsync-facebook`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function fetchSmsTheme(payload) {
  return api()
    .get(`${config.routes.settings}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resetUserAccount(payload) {
  return api()
    .post(`${config.routes.resetUserAccount}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function quickLogin(payload) {
  return api()
    .post(`${config.routes.quickLogin}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createStaff(payload) {
  return api()
    .post(`${config.routes.createStaff}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function createCustomField(payload) {
  return api()
    .post(`${config.routes.createCustomField}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function editCustomField(payload) {
  return api()
    .patch(`${config.routes.createCustomField}/${payload.customFieldId}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function fetchUserRolePermissions(payload) {
  return api()
    .get(`${config.routes.userRolePermissions}/userpermission?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getGmbAccounts(payload) {
  return api(true)
    .post(config.routes.gmbData, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
// nedd to change in feature
export function getGMBDetailsFromGoogle(payload) {
  return api('', payload)
    .get(`${config.routes.gmbLocationsDetails}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
// export function getGMBDetailsFromGoogle (payload) {
//   return api('', payload)
//     .get(`https://mybusinessaccountmanagement.googleapis.com/v1/accounts`)
//     .then((res) => res.data)
//     .catch(catchHandler)
// }
export function getGmbLocations(payload) {
  return api()
    .get(`${config.routes.gmbLocations}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function gmbImport(payload) {
  const value = await getV2ConfigCatValue('GMB')
  if (value) {
    return apiV2()
      .post(`${config.routes.gmbDataSyncV2}/user-sync-google`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(`${config.routes.businessSettings}/user-sync-google`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function userRolePermissions(payload) {
  return api()
    .post(`${config.routes.userRolePermissions}/userpermission`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function gmbSync(payload) {
  return api()
    .post(`${config.routes.accountSettings}/syncgmbsettings`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function gmbUnSync(payload) {
  const value = await getV2ConfigCatValue('GMB')
  if (value) {
    return apiV2()
      .post(`${config.routes.gmbDataSyncV2}/user-unsync-google`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(`${config.routes.businessSettings}/user-unsync-google`)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function gmbActive(payload, rootState) {
  return apiV2Gbm()
    .get('/gmb-active')
    .then((res) => res.data)
    .catch(catchHandler)
}
export function gbmEnabled(payload, rootState) {
  return apiV2Gbm()
    .get('/gbm-enabled')
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createGbmAgent(payload, rootState) {
  return apiV2Gbm()
    .post('/create-gbm-agent', payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateGbmAgent(payload, rootState) {
  return apiV2Gbm()
    .put('/update-gbm-agent', payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getGmbDataOfUser(payload, rootState) {
  return apiV2Gbm()
    .get('/gmb-link-details')
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function getConfigCatValue(flag, payload) {
  if (flag === 'gbmEnabled') {
    const configCatRes = await getFeatureVal(featureFlags.gbmEnabled, payload)
    return configCatRes
  }
  return false
}
export function getEmbedChatScript(payload) {
  console.log(payload)

  return api()
    .get(`${config.routes.messengerEmbedcode}`, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sortPhotos(payload) {
  const requestURL = `${config.routes.businessSettings}/${payload._id}/sort-photo`
  delete payload._id
  return api()
    .put(requestURL, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function uploadBusinessPhoto(payload) {
  const requestURL = `${config.routes.businessSettings}/${payload._id}/photo`
  delete payload._id
  return api()
    .put(requestURL, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getMenuLocations(payload) {
  const requestURL = `${config.routes.menuLocation}/${payload}`
  return api()
    .get(requestURL, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getUser(payload) {
  return api()
    .get(`${config.routes.getUser}/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getCalendarData(payload) {
  return api()
    .get(`${config.routes.getCalendarData}/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateStaffUser(payload) {
  return api()
    .put(`${config.routes.updateStaff}/${payload._id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendWelcomeMessage(payload) {
  return api()
    .post(`${config.routes.sendWelcome}/${payload._id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getActivityList(payload) {
  return api()
    .get(`${config.routes.activities}?${objToUrlParams(payload.query)}&activity_type=${payload.activity_type}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function unLinkCalendar(payload) {
  return api()
    .delete(`${config.routes.unLinkCalendar}/${payload._id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addSectionitem(payload) {
  return api()
    .post(`${config.routes.addMenuSection}/${payload.id}`, payload.payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateSectionitem(payload) {
  return api()
    .put(`${config.routes.addMenuSection}/${payload.id}/${payload.payload._id}`, payload.payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getMenuOptions(payload) {
  return api()
    .get(config.routes.menuLabels)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createMenuItem(payload) {
  return api()
    .post(`${config.routes.menuItems}/${payload.businessId}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateMenuItem(payload) {
  return api()
    .put(`${config.routes.menuItems}/${payload.businessId}/${payload.itemId}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMenuItem(payload) {
  return api()
    .delete(`${config.routes.menuItems}/${payload.section_id}/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMenuSection(payload) {
  return api()
    .delete(`${config.routes.addMenuSection}/${payload.activeMenuId}/${payload.sectionId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createMenu(payload) {
  return api()
    .post(`${config.routes.menuLocation}/${payload.id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function editMenu(payload) {
  return api()
    .put(`${config.routes.menuLocation}/${payload.id}/${payload.menuId}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMenu(payload) {
  return api()
    .delete(`${config.routes.menuLocation}/${payload.id}/${payload.menuId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function reorderMenuItem(payload) {
  return api()
    .put(`${config.routes.addMenuSection}/${payload.id}/${payload.sectionId}/reorder`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function syncGMBPhoto(payload) {
  const data = {}
  if (!payload.photoId) data.url = payload.url
  return api()
    .put(`${config.routes.businessSettings}/${payload.businessId}/photo/${payload.photoId}`, data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function upadteBusinessName(payload) {
  return api()
    .post(`${config.routes.businessSettings}/`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createGMBLocation(payload) {
  return api()
    .post(`${config.routes.businessSettingsAddGMB}/`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function uberAllCategory(payload, module) {
  const value = await getV2ConfigCatValue('uberallBanners')
  if (value) {
    return apiV2Uberall()
      .get(`${config.routes.uberAllCategories}/${payload}?module=${module}`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return ''
  }
}
