import React from 'react'
import Icon from '@components/icons'
import { capitalize } from '@helpers/index'
import moment from 'moment'
import { phoneNumberFormat, UsCurrencyFormatter, getcustomersReferenceParams, isEnabled } from '@helpers/utility'
import ConfirmBookingPopup from '../confirm-booking-popup'
import CancelBookingPopup from '../cancel-booking-popup'
import ReschedulePopup from '../booking-reschedule-popup'
import AddBookingPopup from '../add-booking-popup'
import { connect } from 'react-redux'
import { segmentEventTrack } from '@helpers/segment'
import AddBookingPopupEnterprise from '@containers/enterprise/reporting/utility/addBookingModalV2'
import ReadMoreAndLess from 'react-read-more-less'
import PaymentCompletePopup from '@components/popups/payment-complete-popup'
import BookingPaymentPopupContainer from '@components/popups/booking-payment-popup-container'
import { readSegregateV2Flag } from '@helpers/segregateFlag'
import * as paymentService from '@containers/payment/service'
import { withTranslation } from 'react-i18next'

const Cloader = [1].map((l) => {
  return (
    <div key={l}>
      <div className="placeholder-loader w-100 py-30"></div>
      <div className="placeholder-loader w-100 py-10"></div>
      <div className="placeholder-loader w-100 py-20 mb-20"></div>

      <div className="px-20 px-sm-30">
        <div className="d-flex align-items-center mb-20">
          <div className="placeholder-loader w-100 py-15 mr-15"></div>
          <div className="placeholder-loader btn-edit border-0"></div>
        </div>

        <div className="placeholder-loader w-100 py-15 mb-10"></div>
        <div className="placeholder-loader w-100 py-15 mb-10"></div>
        <div className="placeholder-loader w-100 py-15 mb-10"></div>

        <div className="d-flex align-items-center mb-20 pb-20 border-bottom">
          <div className="placeholder-loader w-100 py-15 mr-15"></div>
          <div className="placeholder-loader w-100 py-15 mr-15"></div>
          <div className="placeholder-loader w-100 py-15"></div>
        </div>

        <div className="mb-20 pb-20">
          <div className="d-flex align-items-center justify-content-between mb-20">
            <div className="placeholder-loader w-75 py-15 mr-30"></div>
            <div className="placeholder-loader w-25 py-15"></div>
          </div>

          <div className="placeholder-loader w-50 py-15 mb-20"></div>

          <div className="d-flex align-items-center justify-content-between mb-20">
            <div className="placeholder-loader w-75 py-15 mr-30"></div>
            <div className="placeholder-loader w-25 py-15"></div>
          </div>
        </div>
      </div>
    </div>
  )
})

const bookingStatus = {
  canceled: 'canceled',
  declined: 'declined',
  pending: 'pending',
  confirmed: 'confirmed',
  completed: 'completed'
}

const mapStateToProps = ({
  auth,
  booking,
  contact,
  accountsettings,
  location,
  enterpriseReporting,
  common,
  services
}) => {
  return {
    ...auth,
    ...booking,
    ...contact,
    ...accountsettings,
    ...location,
    ...enterpriseReporting,
    ...common,
    ...services
  }
}

const mapDispatchToProps = ({
  auth,
  booking,
  contact,
  accountsettings,
  location,
  enterpriseReporting,
  common,
  services
}) => {
  return {
    ...auth,
    ...booking,
    ...contact,
    ...accountsettings,
    ...location,
    ...enterpriseReporting,
    ...common,
    ...services
  }
}

class ServiceCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  fetchAddressName() {
    const { bookingData = {} } = this.props
    let address = ''
    if (bookingData.service_occurs_at === 'business_address') return 'My Business Location'
    else {
      if (bookingData.service_address) {
        address = bookingData.service_address
        if (bookingData.service_address2) {
          address = address ? `${address}, ${bookingData.service_address2}` : bookingData.service_address2
        }
        if (bookingData.service_city) {
          address = address ? `${address}, ${bookingData.service_city}` : bookingData.service_city
        }
        if (bookingData.service_state) {
          address = address ? `${address}, ${bookingData.service_state}` : bookingData.service_state
        }
        if (bookingData.service_zip) {
          address = address ? `${address}, ${bookingData.service_zip}` : bookingData.service_zip
        }
        return address
      } else {
        return "Customer's Location - Not Provided"
      }
    }
  }
  showAddress() {
    const { bookingData = {} } = this.props
    if (bookingData.service_occurs_at === 'business_address') return false
    else {
      if (bookingData.service_address) return true
      else return false
    }
  }

  getDueAmount() {
    const { bookingData = {} } = this.props
    const serviceRate = bookingData.service_rate || 0
    const serviceQuantity = bookingData.service_quantity || 0
    let amount = serviceQuantity * serviceRate
    if (bookingData.payments && bookingData.payments.length) {
      let paidAmount = 0
      const amounts = bookingData.payments.map((item) =>
        item.status !== 'draft' &&
        item.status !== 'deleted' &&
        item.status !== 'canceled' &&
        item.status !== 'refund' &&
        item.status !== 'declined'
          ? item.amount
          : 0
      )
      paidAmount = amounts.reduce((acc, currentValue) => acc + currentValue)
      if (amount !== 0) amount -= paidAmount
      else amount = paidAmount
    }
    return UsCurrencyFormatter(amount)
  }

  hasDueAmount() {
    const { bookingData = {} } = this.props
    const serviceRate = bookingData.service_rate || 0
    const serviceQuantity = bookingData.service_quantity || 0
    let amount = serviceQuantity * serviceRate
    if (bookingData.payments && bookingData.payments.length) {
      let paidAmount = 0
      const amounts = bookingData.payments.map((item) =>
        item.status !== 'draft' &&
        item.status !== 'deleted' &&
        item.status !== 'canceled' &&
        item.status !== 'refund' &&
        item.status !== 'declined'
          ? item.amount
          : 0
      )
      paidAmount = amounts.reduce((acc, currentValue) => acc + currentValue)
      amount -= paidAmount
    }
    return amount
  }

  async cancelBooking() {
    await this.props.cancelBooking({ appId: [this.props.bookingData._id] })
    this.props.getBookingDetails(this.props.bookingData._id)
    this.props.getBookingHistory(this.props.bookingData._id)
    if (this.props.onBookingUpdate) this.props.onBookingUpdate()
  }

  async CancelPopupShow() {
    const res = await this.CancelBookingPopup.showPopup()
    if (res) {
      await this.props.cancelBooking({ appId: [this.props.bookingData._id] })
      this.props.getBookingDetails(this.props.bookingData._id)
      this.props.getBookingHistory(this.props.bookingData._id)
      if (this.props.onBookingUpdate) this.props.onBookingUpdate()
    }
  }
  async ConfirmPopupShow() {
    const res = await this.ConfirmBookingPopup.showPopup()
    if (res) {
      await this.props.confirmBooking({ appId: [this.props.bookingData._id] })
      this.props.getBookingDetails(this.props.bookingData._id)
      this.props.getBookingHistory(this.props.bookingData._id)
      if (this.props.onBookingUpdate) this.props.onBookingUpdate()
    }
  }

  async rescheduleSubmit(values, resetForm) {
    if (values) {
      this.setState({ bookingloading: true })
      await this.props.rescheduleBooking({ appId: [this.props.bookingData._id], res: values })
      if (!this.props.apiError) {
        this.setState({ bookingloading: false, rescheduleId: '' })
        this.ReschedulePopup.closeModal(resetForm)
        this.props.getBookingDetails(this.props.bookingData._id) // this.props.bookingData
        this.props.getBookingHistory(this.props.bookingData._id)
        if (this.props.onBookingUpdate) this.props.onBookingUpdate()
      }
      this.setState({ bookingloading: false })
    }
  }

  async ReschedulePopupShow() {
    if (this.props.bookingData && this.props.bookingData.service_id) {
      await this.setState({ rescheduleService: this.props.bookingData.service_id })
    }
    await this.ReschedulePopup.showPopup()
  }

  async editBooking(values) {
    const payload = { bookingId: this.props.bookingData._id, data: { ...values } }
    if (this.props.is_enterprise) {
      payload.query = { customers: values.location }
    }
    this.setState({ bookingloading: true })
    await this.props.editBooking(payload)
    this.setState({ bookingloading: false })
    this.closeBooking()
    if (this.props.bookingresponse && this.props.bookingresponse._id) {
      this.props.getBookingDetails(this.props.bookingData._id) // this.props.bookingData
      this.props.getBookingHistory(this.props.bookingData._id)
      if (this.props.onBookingUpdate) this.props.onBookingUpdate()
    }
  }

  closeBooking() {
    this.setState({ openBookingModal: false, editBookingDetails: null })
  }

  async autoSuggestContacts(payload) {
    await this.props.autosuggestContacts(payload)
    let contactList = []
    if (this.props.searchRes) {
      this.props.searchRes.forEach((element) => {
        if (element.type === 'contact') {
          const data = {
            label: element.first_name ? `${element.first_name} ${element.last_name ? element.last_name : ''}` : '',
            subLabel: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
            value: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
            type: 'contact',
            image: element.image ? element.image : '',
            contactObj: element
          }
          contactList = [...contactList, data]
        }
      })
      this.setState({ contactList: contactList })
    }
  }
  async getSelectedLocationService(customer) {
    const customerIds = []
    customerIds.push(customer)
    const obj = {
      nopaging: true,
      refType: 'reference_number'
    }
    const customers = getcustomersReferenceParams(customerIds, this.props.enterpriselocations)
    await this.props.getPartnerServices(obj, customers)
  }

  async createServicePartner(data, customer) {
    const newSerVice = await this.props.createPartnerService(data, customer)
    if (!this.props.AddServiceError && newSerVice) {
      await this.getSelectedLocationService(customer)
      return newSerVice
    }
  }
  async componentDidMount() {
    const flag = await readSegregateV2Flag(
      this.props.accountsettings.payment_type,
      'payment_v2',
      this.props.products,
      this.props.me
    )
    this.setState({ payment_v2: flag })
  }
  async savePrivateContact(payload) {
    await this.props.savePrivateContact(payload)
    if (this.props.privateContact && this.props.privateContact.data) {
      this.props.segmentConatctTrack({ contactIds: [this.props.privateContact.data._id] })
    }
    if (!this.props.apiError) {
      const segmentData = {
        event: 'contact_added',
        properties: {
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name,
          has_email: !!payload.email,
          has_phone: !!payload.mobile,
          has_address: !!(payload.address || payload.address2)
        }
      }
      segmentEventTrack(segmentData)
    }
  }
  async createService(data) {
    const newSerVice = await this.props.createService(data)
    if (!this.props.AddServiceError && newSerVice) {
      const paylod = { userType: this.props.me && this.props.me.role_id && this.props.me.role_id.role_name }
      await this.props.getBookingServicesOnly(paylod)
      return newSerVice
    }
  }

  async addBooking() {
    if (this.props.is_enterprise) {
      if (!this.props.enterpriselocations) {
        await this.props.getEnterpriseLocations()
      }
      const locations = []
      this.props.enterpriselocations &&
        this.props.enterpriselocations.forEach((loc) => {
          if (loc.location_identifier && loc.city) loc.name = loc.location_identifier + ' ' + loc.city
          else if (loc.city) loc.name = loc.city
          else if (loc.location_identifier) loc.name = loc.location_identifier
          else if (loc.locationName) loc.name = loc.locationName
          else loc.name = loc.displayname
          if (loc.products.indexOf('booking') > -1) {
            locations.push({ _id: loc.customer, name: loc.name })
          }
        })
      await this.setState({ locations: locations, selectedLocations: locations })
      await this.getServices()
    }

    await this.setState({ openBookingModal: true, editBookingDetails: this.props.bookingData })
    this.setState({ serviceLoading: true })
    const paylod = { userType: this.props.me && this.props.me.role_id && this.props.me.role_id.role_name }
    await this.props.getBookingServicesOnly(paylod)
    this.setState({ serviceLoading: false })
  }
  async getServices() {
    let customerIds = []
    customerIds = this.state.selectedLocations.map((loc) => {
      return loc._id
    })
    const obj = {
      nopaging: true,
      refType: 'reference_number'
    }
    const customers = getcustomersReferenceParams(customerIds, this.props.enterpriselocations)
    await this.props.getPartnerServices(obj, customers)
  }
  async getServicesBySource() {
    await this.setState({ loading: true })
    const obj = {
      nopaging: true,
      search: this.state.searchKey ? this.state.searchKey : undefined,
      sort: this.state.itemKey ? this.state.itemKey : 'sort_order',
      source: 'direct'
    }
    if (this.props.me && this.props.me.role_id && this.props.me.role_id.role_name !== 'staff') {
      await this.props.getServices(obj)
    } else {
      await this.props.getServices(obj)
    }
    await this.setState({ loading: false })
  }
  openMap() {
    const address = this.fetchAddressName()
    window.open(`https://maps.google.com/?q=${address}`, '_blank')
  }
  formatAddress(address) {
    return address
      .split(' ')
      .filter((item) => item)
      .map((item) => item.trim())
      .join('+')
  }
  showPaymentCompletePopup() {
    this.setState({ openPaymentComplete: true })
  }
  closePaymentCompletePopup() {
    this.setState({ openPaymentComplete: false })
  }
  completePayment(values) {
    // open booking payment complete popup
    this.setState(
      {
        bookingDetails: values,
        paymentCompleteData: values.paymentCompleteData
      },
      () => {
        this.closePaymentCompletePopup()
        this.openBookingPayment()
      }
    )
  }
  async completeBookingPayment(values) {
    this.setState({ bookingloading: true })
    const payload = { ...this.state.paymentCompleteData }
    if (this.state.paymentCompleteData && this.state.paymentCompleteData.collect_via === 'direct') {
      payload.card_holder = values.card_holder
      payload.card_token = values.card_token
      payload.postal = values.postal
      payload.expiry = values.expiry
      payload.cvv = values.cvv

      payload.paymentMethod = values.paymentMethod
      payload.stripeObj = values.stripeObj
      await this.props.collectBookingDueManualPayment(payload)
      const postPayload = {
        paymentIntentId: payload.paymentIntentId,
        merchantId: this.props.accountsettings.payment.merchant_id,
        inputSource: 'booking'
      }
      await paymentService.proceedPostStripePayment(postPayload)
    } else if (this.state.paymentCompleteData && this.state.paymentCompleteData.collect_via === 'getpaid') {
      // CALL TO GET PAID API
      let amount
      if (typeof values.payment_amount === 'number') {
        amount = values.payment_amount
      } else if (typeof values.payment_amount === 'string') {
        if (values.payment_amount.includes(',')) {
          amount = Number(values.payment_amount.replace(',', ''))
        } else {
          amount = values.payment_amount
        }
      }
      const res = {
        contact_id:
          this.state.bookingDetails && this.state.bookingDetails.contact && this.state.bookingDetails.contact._id
            ? this.state.bookingDetails.contact._id
            : '',
        customer_name:
          this.state.bookingDetails && this.state.bookingDetails.contact && this.state.bookingDetails.contact.full_name
            ? this.state.bookingDetails.contact.full_name
            : '',
        message: '',
        requested_amount: amount,
        // requested_amount: Number(values.minimum_payment_amount.replace(',', '')),
        requested_through: {
          email: !!(
            this.state.bookingDetails &&
            this.state.bookingDetails.contact &&
            this.state.bookingDetails.contact.email
          ),
          sms: !!(
            this.state.bookingDetails &&
            this.state.bookingDetails.contact &&
            this.state.bookingDetails.contact.mobile
          )
        },
        customer_email:
          this.state.bookingDetails && this.state.bookingDetails.contact && this.state.bookingDetails.contact.email,
        customer_number:
          this.state.bookingDetails && this.state.bookingDetails.contact && this.state.bookingDetails.contact.mobile,
        booking_id: payload.booking_id,
        collectPayment: true
      }
      await this.props.sendBookingGetPaidLink(res)
      // await this.props.collectBookingDuePayment(payload)
    }
    this.setState({ bookingloading: false })
    this.closeBookingPayment()
    if (!this.props.apiError) {
      this.props.getBookingDetails(this.props.bookingData._id)
      this.props.getBookingHistory(this.props.bookingData._id)
      if (this.props.onBookingUpdate) this.props.onBookingUpdate()
    }
  }
  openBookingPayment() {
    this.setState({ showPaymentPopup: true })
  }
  closeBookingPayment() {
    this.setState({ showPaymentPopup: false, paymentCompleteData: null })
  }
  showPaymentDeatils() {
    const { bookingData = {} } = this.props
    let payments = []
    if (bookingData.payments && bookingData.payments.length) {
      payments = bookingData.payments.filter((item) => {
        return item.status !== 'draft' && item.status !== 'deleted'
      })
    }
    return payments.map((item, index) => {
      if (
        item.status !== 'draft' &&
        item.status !== 'deleted' &&
        item.status !== 'refund' &&
        item.status !== 'canceled' &&
        item.status !== 'declined'
      ) {
        return (
          <div className="d-flex justify-content-between" key={index}>
            <div className="strike">
              {item.status === 'accepted' || item.status === 'processing' || item.status === 'capture'
                ? `Deposit ${payments.length > 1 ? index + 1 : ''}`
                : item.status === 'pending'
                ? `Deposit (Due) ${payments.length > 1 ? index + 1 : ''}`
                : null}
            </div>
            <div className="strike">-${UsCurrencyFormatter(item.amount)}</div>
          </div>
        )
      } else return null
    })
  }
  render() {
    const { t } = this.props
    const { bookingData = {} } = this.props

    return (
      <div className="quick-view-service-wrapper pb-30 mb-20 border-bottom">
        {this.props._loading ? (
          <div>{Cloader}</div>
        ) : (
          <>
            <div className="px-20 px-sm-30">
              <div className="d-flex align-items-center justify-content-between mb-10">
                <div className="d-flex align-items-end">
                  <h2 className="mr-5">Services</h2>
                  <span
                    className={`position-relative top-minus-2 booking-status fz-12 primary-font-bold text-uppercase ${
                      bookingData.status === bookingStatus.canceled || bookingData.status === bookingStatus.declined
                        ? 'text-danger'
                        : bookingData.status === bookingStatus.pending
                        ? 'text-warning'
                        : 'text-success'
                    }`}>
                    {capitalize((bookingData.status === 'pending' ? 'pending confirmation' : bookingData.status) || '')}
                  </span>
                </div>
                {bookingData.status !== 'canceled' &&
                bookingData.status !== 'declined' &&
                this.props.permissions.isBookingPermitted ? (
                  <div>
                    <button
                      type="button"
                      className="rounded d-flex align-items-center p-0 justify-content-center btn-edit"
                      id="btnReviewShareableBtn"
                      onClick={this.addBooking.bind(this)}>
                      <i className="btn-icon d-flex">
                        <Icon name="notePencil" />
                      </i>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="d-block">
                <div className="d-flex align-items-center mb-10">
                  <i className="btn-icon d-flex justify-content-center mr-10 icon-schedule top-minus-1">
                    <Icon name="clock2" />
                  </i>
                  <span className="text-secondary mr-5 ml-2 d-inline-block">
                    {moment.utc(bookingData.appointment_start_date).format('MMM DD, YYYY')} -&nbsp;
                  </span>
                  <strong className="text-secondary d-inline-block">
                    {moment.utc(bookingData.appointment_start_date).format('hh:mma')}
                    {bookingData.appointment_end_date
                      ? ` - ${moment.utc(bookingData.appointment_end_date).format('hh:mma')}`
                      : null}
                  </strong>
                </div>
                <div className="d-flex align-items-start mb-10">
                  <i className="btn-icon top-1 d-flex justify-content-center mr-10 icon-location">
                    <Icon name="locationPointer" />
                  </i>
                  {this.showAddress() ? (
                    <span
                      className="ml-2 d-inline-block cursor-pointer text-primary "
                      onClick={this.openMap.bind(this)}>
                      {this.fetchAddressName()}
                    </span>
                  ) : (
                    <span className="ml-2 d-inline-block text-secondary">{this.fetchAddressName()}</span>
                  )}
                </div>
                {bookingData && bookingData.assigned_user && bookingData.assigned_user.full_name ? (
                  <div className="d-flex align-items-center mb-10">
                    {bookingData.assigned_user.profile_picture ? (
                      <figure className="mb-0 mr-10 position-relative top-minus-1">
                        <img
                          className="rounded-circle profile-image"
                          src={bookingData.assigned_user.profile_picture}
                          alt=""
                        />
                      </figure>
                    ) : (
                      <i className="btn-icon d-flex justify-content-center mr-10 icon-location">
                        <Icon name="contactUser" />
                      </i>
                    )}
                    <span className="text-secondary ml-2 d-inline-block">
                      {capitalize(bookingData.assigned_user.full_name)}
                    </span>
                  </div>
                ) : null}
              </div>
              {bookingData &&
              bookingData.status !== bookingStatus.canceled &&
              bookingData.status !== bookingStatus.declined ? (
                <div
                  className={`d-flex justify-content-between py-10 ${bookingData.customer_message ? '' : 'mb-15'}   ${
                    bookingData.status === bookingStatus.confirmed ? 'status-confirmed' : ''
                  }`}>
                  <button
                    className={`btn btn-block btn-outline ${
                      bookingData.status === bookingStatus.confirmed
                        ? 'btn-confirm-animation overflow-hidden mr-0 p-0 border-0'
                        : 'mr-10'
                    }`}
                    onClick={this.ConfirmPopupShow.bind(this)}>
                    <i
                      className={`btn-icon top-minus-3 icon-tick-only ${
                        bookingData.status === bookingStatus.confirmed ? 'icon-color-change' : ''
                      }`}>
                      <Icon name="confirmTick" />
                    </i>
                    <span className={'ml-10 text-transition '}>CONFIRM</span>
                  </button>
                  {this.props.permissions.isBookingPermitted && (
                    <div className="btn-block d-flex align-items-center mt-0">
                      <button
                        className={'btn btn-block btn-outline overflow-hidden'}
                        onClick={this.ReschedulePopupShow.bind(this)}>
                        <i className={'btn-icon top-minus-3 icon-calendar-date'}>
                          <Icon name="calendarDate" />
                        </i>
                        <span
                          className={`${
                            bookingData.status === bookingStatus.confirmed ? '' : 'd-none'
                          } ml-10 text-transition`}>
                          RESCHEDULE
                        </span>
                      </button>
                      <button
                        className={'btn ml-10 btn-block btn-outline-danger mt-0 overflow-hidden'}
                        onClick={this.CancelPopupShow.bind(this)}>
                        <i className={'btn-icon top-minus-3 icon-close'}>
                          <Icon name="closeThin" />
                        </i>
                        <span
                          className={`${
                            bookingData.status === bookingStatus.confirmed ? '' : 'd-none'
                          } ml-10 text-transition`}>
                          CANCEL
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            {bookingData && bookingData.customer_message ? (
              <div className="pb-20 pt-10 mb-20 border-bottom border-color-light">
                <div className="px-20 px-sm-30">
                  <h5 className="mb-10 light-text-color fz-12">APPOINTMENT MESSAGE</h5>
                  <div className="mb-3">
                    <ReadMoreAndLess
                      ref={this.ReadMore}
                      className="read-more-content"
                      charLimit={50}
                      readMoreText=" More"
                      readLessText=" Less">
                      {bookingData.customer_message}
                    </ReadMoreAndLess>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="px-20 px-sm-30">
              <div
                className={`pb-20 mb-20 border-bottom ${
                  bookingData.status === bookingStatus.canceled || bookingData.status === bookingStatus.declined
                    ? 'strikethrough'
                    : ''
                }`}>
                <div className="services-list d-flex justify-content-between">
                  <div>
                    <strong className="strike text-secondary">{capitalize(bookingData.service_name || '')}</strong>
                    <div className="strike text-break">
                      {(bookingData.service_id && bookingData.service_id.descripton) || ''}
                    </div>
                    <div className="strike">
                      {`${bookingData.service_quantity || 0} x ${UsCurrencyFormatter(
                        bookingData.service_rate || 0
                      )} ${t('common.each')}`}
                    </div>
                  </div>
                  <div className="strike">
                    ${UsCurrencyFormatter((bookingData.service_quantity || 0) * bookingData.service_rate)}
                  </div>
                </div>
              </div>
              {/* {bookingData.payment_id && bookingData.payment_id.paid_amount && !bookingData.payment_id.balance_paid ? */}
              {(bookingData.service_quantity || 0) * bookingData.service_rate &&
              bookingData.payments &&
              bookingData.payments.length ? (
                <div
                  className={`mb-20 pb-20 border-bottom ${
                    bookingData.status === bookingStatus.canceled || bookingData.status === bookingStatus.declined
                      ? 'strikethrough'
                      : ''
                  }`}>
                  <div className="d-flex justify-content-between mb-5">
                    <div className="strike">Subtotal</div>
                    <div className="strike">
                      ${UsCurrencyFormatter((bookingData.service_quantity || 0) * bookingData.service_rate)}
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between">
                                        <div className="strike">Deposit</div>
                                        <div className="strike">- ${UsCurrencyFormatter((bookingData.payment_id && bookingData.payment_id.paid_amount) || 0)}</div>
                                    </div> */}
                  {bookingData.payments && bookingData.payments.length ? this.showPaymentDeatils() : null}
                </div>
              ) : null}

              <div
                className={`d-flex justify-content-between ${
                  bookingData.status === bookingStatus.canceled || bookingData.status === bookingStatus.declined
                    ? 'strikethrough'
                    : ''
                }`}>
                <strong className="strike text-secondary">
                  {bookingData.payment_id && bookingData.payment_id.balance_paid ? 'Total' : 'Balance Due'}
                </strong>
                <strong className="strike text-secondary">${this.getDueAmount()}</strong>
              </div>
              {/* {this.hasDueAmount() && (!bookingData.payment_id || !bookingData.payment_id.balance_paid) ? */}
              {isEnabled('payment', this.props.products) &&
              this.state.payment_v2 &&
              this.hasDueAmount() &&
              bookingData.status !== bookingStatus.canceled &&
              bookingData.status !== bookingStatus.declined &&
              bookingData.appointment_end_date &&
              moment().isBefore(bookingData.appointment_end_date.replace('Z', '')) ? (
                <div className="mt-10">
                  <button
                    onClick={this.showPaymentCompletePopup.bind(this)}
                    className="btn btn-block mt-0 px-15 btn-outline">
                    <i className="btn-icon icon-email">
                      <Icon name="dollarCoin" />
                    </i>
                    <span className="d-inline-block ml-10 fz-12">COLLECT PAYMENT</span>
                  </button>
                </div>
              ) : null}
            </div>
          </>
        )}
        <ConfirmBookingPopup ref={(ref) => (this.ConfirmBookingPopup = ref)} />
        <CancelBookingPopup ref={(ref) => (this.CancelBookingPopup = ref)} />
        <ReschedulePopup
          ref={(ref) => (this.ReschedulePopup = ref)}
          slotList={this.props.dateSlots}
          processbooking={this.state.bookingloading}
          onSubmit={this.rescheduleSubmit.bind(this)}
          rescheduleService={this.state.rescheduleService}
          getUnAvailableDateSlots={this.props.getUnAvailableDateSlots}
          unAvailabledateSlots={this.props.unAvailabledateSlots}
          userTimeZone={this.props.userTimeZone}
        />
        {this.state.openBookingModal && !this.props.is_enterprise ? (
          <AddBookingPopup
            ref={(ref) => (this.bookingPopup = ref)}
            open={this.state.openBookingModal}
            services={this.props.services}
            customFields={this.props.customFields}
            getServices={this.getServicesBySource.bind(this)}
            predefinedTemplates={this.props.predefinedTemplates}
            processbooking={this.state.bookingloading}
            onSubmit={this.editBooking.bind(this)}
            close={this.closeBooking.bind(this)}
            getUnAvailableDateSlots={this.props.getUnAvailableDateSlots}
            unAvailabledateSlots={this.props.unAvailabledateSlots}
            products={this.props.products}
            autosuggestContacts={this.autoSuggestContacts.bind(this)}
            contactList={this.state.contactList}
            savePrivateContact={this.savePrivateContact.bind(this)}
            privateContact={this.props.privateContact}
            me={this.props.me}
            accountsettings={this.props.accountsettings}
            editBookingDetails={this.state.editBookingDetails}
            bookingType={'edit'}
            serviceLoading={this.state.serviceLoading}
            user={this.props.user}
            userTimeZone={this.props.userTimeZone}
            createService={this.createService.bind(this)}
          />
        ) : null}
        {this.state.openBookingModal && this.props.is_enterprise ? (
          <AddBookingPopupEnterprise
            ref={(ref) => (this.bookingPopup = ref)}
            open={this.state.openBookingModal}
            serviceList={this.props.serviceList}
            predefinedTemplates={this.props.predefinedTemplates}
            processbooking={this.state.bookingloading}
            onSubmit={this.editBooking.bind(this)}
            close={this.closeBooking.bind(this)}
            getUnAvailableDateSlots={this.props.getUnAvailableDateSlots}
            unAvailabledateSlots={this.props.unAvailabledateSlots}
            products={this.props.products}
            autosuggestContacts={this.autoSuggestContacts.bind(this)}
            contactList={this.state.contactList}
            savePrivateContact={this.savePrivateContact.bind(this)}
            privateContact={this.props.privateContact}
            me={this.props.me}
            accountsettings={this.props.accountsettings}
            editBookingDetails={this.state.editBookingDetails}
            bookingType={'edit'}
            serviceLoading={this.state.serviceLoading}
            user={this.props.user}
            loadBusinessSettings={this.props.loadBusinessSettings}
            fetchAccountSettings={this.props.fetchAccountSettings}
            getLocationAccountSettings={this.props.getLocationAccountSettings}
            userAccSettings={this.props.userAccSettings}
            locations={this.props.formatedLocations}
            partnerServices={this.props.partnerServices}
            userTimeZone={this.props.userTimeZone}
            getSelectedLocationService={this.getSelectedLocationService.bind(this)}
            createService={this.createServicePartner.bind(this)}
          />
        ) : null}
        {this.state.openPaymentComplete ? (
          <PaymentCompletePopup
            open={this.state.openPaymentComplete}
            close={this.closePaymentCompletePopup.bind(this)}
            bookingDetails={this.props.bookingData}
            handleSubmit={this.completePayment.bind(this)}
          />
        ) : null}
        {this.state.showPaymentPopup ? (
          <BookingPaymentPopupContainer
            open={this.state.showPaymentPopup}
            module="Booking"
            payloadData={this.state.bookingDetails}
            submitText={'COLLECT PAYMENT'}
            isEdit={false}
            submitData={this.completeBookingPayment.bind(this)}
            closeModal={this.closeBookingPayment.bind(this)}
            cancelIncompleteBooking={this.cancelBooking.bind(this)}
            predefinedTemplates={this.props.predefinedTemplates}
            isFromBookingQuickView={true}
            // onBookingEdit={this.onBookingEdit.bind(this)}
            loader={this.state.bookingloading}
            me={this.props.me}
          />
        ) : null}
      </div>
    )
  }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ServiceCard))
