import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SendMessagePopup } from '@components/popups/sendMessagePopupBox'
import { ReviewRequestPopup } from '@components/popups/reviewRequestPopup'
import SendInvoicePopup from '../payment/utility/send-invoice-popup'
import SendInvoiceConfirmPopup from '../payment/utility/send-invoice-confirm-popup'
import SendInvoiceSuccessPopup from '../payment/utility/send-invoice-success-popup'
import { phoneNumberFormat } from '@helpers/utility'
import { useIsPermitted } from '@helpers/permission-hooks'
import { segmentEventTrack, trackPaymentRequestSent, trackInvoiceRequestSent } from '@helpers/segment'
import * as service from '../payment/service'
import { PAYMENT_STATUS } from '../payment/constants'
import SendBookingLinkPopup from '@components/popups/sendBookingLinkPopup'
import AddContactPopup from '../contacts-hub/utility/add-contact-popup'
import CreateGroupPopup from '../contacts-hub/utility/create-group-popup'
import { EditEstimatePopup } from '@components/estimates/EditEstimatePopup'
import { ReviewEstimatePopup } from '@components/estimates/ReviewEstimatePopup'
import { EstimateSuccessPopup } from '@components/estimates/EstimateSuccessPopup'
import { EstimateErrorPopup } from '@components/estimates/EstimateErrorPopup'
import { RenewEstimatePopupContainer } from '@containers/estimate/RenewEstimatePopupContainer'
import { CreatePolicyPopup } from '@components/insurances/CreatePolicyPopup'

import { Box } from '@gositeinc/ui'
import { history } from '@app/store'
import moment from 'moment'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const FETCH_PAYMENTS_STATUS_QUERY = Object.values(PAYMENT_STATUS)
  .filter((status) => status !== PAYMENT_STATUS.DRAFT)
  .join(',')

const SendInvoicePopupContainer = ({ open, setOpen, defaultInvoice = undefined, estimate = undefined }) => {
  const dispatch = useDispatch()
  const permissions = useIsPermitted()
  const popupRef = useRef(undefined)
  const me = useSelector((state) => state.auth.me)
  const products = useSelector((state) => state.auth.products)
  const onBoardingData = useSelector((state) => state.payment.onBoardingData)
  const privateContact = useSelector((state) => state.payment.privateContact)
  const previousTransCount = useSelector((state) => state.payment.previousTransCount)
  const mccCodes = useSelector((state) => state.payment.mccCodes)
  const searchRes = useSelector((state) => state.contact.searchRes)
  const messengerService = useSelector((state) => state.messenger.services)
  const paymentsGrowthActions = useSelector((state) => state.paymentsGrowth.actions)
  const accountsettings = useSelector((state) => state.accountsettings.accountsettings)

  const [contactList, setContactList] = useState([])
  const [servicesList, setServiceList] = useState([])
  const [invoiceItem, setInvoiceItem] = useState(undefined)
  const [invoiceSaveLoader, setInvoiceSaveLoader] = useState(false)
  const [showInvoiceSucessModal, setShowInvoiceSucessModal] = useState(false)
  const [trackedFirstTransactions, setTrackedFirstTransactions] = useState(false)

  const [invoiceConfirmModalProps, setInvoiceConfirmModalProps] = useState({
    open: false,
    invoiceData: undefined
  })

  useEffect(() => {
    if (defaultInvoice && popupRef.current) {
      popupRef.current.updateInvoiceForm({
        newInvoice: defaultInvoice,
        shouldDisableContactInputs: true,
        shouldDisableItems: true
      })
    }
  }, [defaultInvoice])

  useEffect(() => {
    if (!onBoardingData) {
      dispatch.payment.fetchOnboarding()
    }
  }, [onBoardingData])

  const hasMid = !!(me && me.account_settings && me.account_settings.payment && me.account_settings.payment.merchant_id)

  const autoSuggestContacts = async (payload) => {
    await dispatch.contact.autosuggestContacts(payload)
    let contactList = []
    if (!searchRes) {
      return
    }

    searchRes.forEach((element) => {
      if (element.type !== 'contact') {
        return
      }

      const data = {
        label: element.first_name ? `${element.first_name} ${element.last_name ? element.last_name : ''}` : '',
        subLabel: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
        value: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
        type: 'contact',
        image: element.image ? element.image : '',
        contactObj: element
      }
      contactList = [...contactList, data]
    })

    setContactList(contactList)

    return contactList
  }

  const savePrivateContact = async (payload) => {
    await dispatch.payment.savePrivateContact(payload)
    if (privateContact && privateContact.data) {
      dispatch.contact.segmentConatctTrack({
        contactIds: [privateContact.data._id]
      })
    }
    return privateContact
  }

  const autoSuggestServices = async (payload) => {
    await dispatch.messenger.getServices(payload)
    let servicesList = []
    if (!messengerService) {
      return
    }
    messengerService.forEach((element) => {
      const data = {
        label: element.title ? element.title : '',
        subLabel: element.price ? element.price : '',
        value: element._id
      }
      servicesList = [...servicesList, data]
    })

    setServiceList(servicesList)
  }

  const updateContact = async (payload) => {
    return dispatch.contact.updateContact(payload)
  }

  const getTrackingProps = () => {
    const { _id = '' } = me
    const userUniqueId = _.get(me, 'user_unique_id', '')
    const companyId = _.get(me, 'company_id', '')
    const businessName = _.get(me, 'bussiness_name', '')
    const createdAt = _.get(me, 'created_at', '')
    const userEmail = _.get(me, 'user_email', '')
    const role = _.get(me, 'role_id.role_name', '')
    const businessCategory = _.get(me, 'primaryCategories.category_name', '')
    const mccCode = mccCodes

    const trackingProps = {
      user_id: _id,
      user_unique_id: userUniqueId,
      company_id: companyId,
      business_name: businessName,
      role,
      created_at: createdAt,
      mcc_code: mccCode,
      business_category: businessCategory,
      business_email_address: userEmail
    }
    return trackingProps
  }

  const firstTimePaymentTracking = async (type, amount) => {
    if (!trackedFirstTransactions) {
      await dispatch.payment.fetchPreviousTransCount({
        start: 0,
        source: 'invoice,getpaid,direct',
        need_invoice_item: true,
        status: FETCH_PAYMENTS_STATUS_QUERY,
        dateOnly: true
      })
      if (previousTransCount <= 1) {
        const trackData = {
          event: 'first_time_payment_requested',
          properties: {
            type: type,
            total: parseFloat(amount),
            role: me.role_id.role_name
          }
        }
        segmentEventTrack(trackData) // TRACK FIRST TRANSACTION
        setTrackedFirstTransactions(true)
      } else {
        setTrackedFirstTransactions(true)
      }
    }
  }

  const saveInvoiceData = async (payload) => {
    setInvoiceSaveLoader(true)
    // pass the extra properties for tracking transactions
    const trackingProps = getTrackingProps()
    let res = await dispatch.payment.saveInvoiceData({
      ...payload,
      ...(estimate ? { estimateId: estimate.id } : {})
    })
    if (!res) {
      res = await dispatch.payment.returnInvoiceData(payload)
    }

    if (!res) {
      setInvoiceSaveLoader(false)
      setInvoiceConfirmModalProps({
        ...invoiceConfirmModalProps,
        open: false
      })
      return
    }

    setInvoiceConfirmModalProps({
      ...invoiceConfirmModalProps,
      open: false
    })

    setInvoiceSaveLoader(false)
    setShowInvoiceSucessModal(true)

    const { data = {} } = res
    const { tip = 0 } = data
    const parentUser = _.get(data, 'parent_user', '')
    const invoiceStatus = _.get(data, 'invoice_status', '')
    const invoiceAmount = _.get(data, 'invoice_amount', 0)
    const invoiceDue = _.get(data, 'invoice_due', '')
    const collectPayments = _.get(data, 'collect_payments', false)
    const { reminder = false } = payload
    const customerPhone = _.get(payload, 'customer_phone', '')
    const customerEmail = _.get(payload, 'customer_email', '')
    const invoiceItems = _.get(payload, 'invoice_items', [])
    const sendType = _.get(payload, 'send_type', '')
    const platform = _.get(data, 'metadata.device')
    const sendSchedule = sendType === 'schedule' ? 'scheduled' : 'immediately'
    await service.trackTransactionStatusChange({
      userId: parentUser,
      status: invoiceStatus,
      amount: invoiceAmount,
      tip
    })

    let customServiceStatus = false
    const lineItemsListed = {}
    invoiceItems.forEach((item) => {
      if (!item['service_id']) {
        customServiceStatus = true
      }
      const service = item['service_name']
      const quantity = parseInt(item['service_quantity'])
      lineItemsListed[service] = quantity
    })

    const paymentSentTrackingData = {
      amount: invoiceAmount,
      total: invoiceAmount,
      payment_request_type: 'invoice',
      platform,
      ...trackingProps
    }

    const invoiceSentTrackingData = {
      amount: invoiceAmount,
      send_to_contacthub: true,
      end_user_phone: customerPhone,
      end_user_email_address: customerEmail,
      send_schedule: sendSchedule,
      smart_reminder_status: reminder,
      due_date: invoiceDue,
      line_items_listed: lineItemsListed,
      line_items_count: invoiceItems.length,
      custom_service_status: customServiceStatus,
      platform,
      collect_payments: collectPayments,
      ...trackingProps
    }

    trackPaymentRequestSent(paymentSentTrackingData)
    trackInvoiceRequestSent(invoiceSentTrackingData)
    if (!payload.invoice_id) {
      const data = {
        event: 'invoices_sent',
        properties: {
          user_id: me._id,
          role: me.role_id.role_name,
          step: 'NewInvoice-ItemsAdded',
          id: _.get(privateContact, 'data._id', ''),
          total: payload.invoice_amount,
          items: payload.invoice_items.length,
          send_type: payload.send_type
        }
      }
      if (me.primaryCategories && me.primaryCategories.category_name) {
        data.properties.business_category = me.primaryCategories.category_name
      }
      segmentEventTrack(data)
      firstTimePaymentTracking('invoice', payload.invoice_amount)
    }
  }

  return (
    <>
      <SendInvoicePopup
        ref={popupRef}
        open={open}
        closeModal={() => {
          setOpen(false)
        }}
        autosuggestContacts={autoSuggestContacts}
        contactList={contactList}
        me={me}
        savePrivateContact={savePrivateContact}
        privateContact={privateContact}
        confirmInvoice={(invoiceData) => {
          setInvoiceConfirmModalProps({
            open: true,
            invoiceData
          })
          setOpen(false)
        }}
        products={products}
        autoSuggestServices={autoSuggestServices}
        servicesList={servicesList}
        invoiceItem={invoiceItem}
        paymentStatus={_.get(onBoardingData, 'status', '')}
        hasMid={hasMid}
        showInstruction={'create_invoice' in paymentsGrowthActions}
        permissions={permissions}
      />

      {invoiceConfirmModalProps.open && (
        <SendInvoiceConfirmPopup
          open={invoiceConfirmModalProps.open}
          closeModal={() => {
            setInvoiceConfirmModalProps({
              open: false,
              invoiceData: undefined
            })
          }}
          invoiceData={invoiceConfirmModalProps.invoiceData}
          me={me}
          invoiceEdit={(invoiceData) => {
            setInvoiceConfirmModalProps({
              open: false,
              invoiceData: undefined
            })
            setInvoiceItem(invoiceData)
            setOpen(true)
          }}
          saveInvoiceData={saveInvoiceData}
          invoiceSaveLoader={invoiceSaveLoader}
          updateContact={updateContact}
          paymentFeedbackStatus={
            accountsettings && accountsettings.payment_feedback ? accountsettings.payment_feedback : false
          }
        />
      )}

      {showInvoiceSucessModal && (
        <SendInvoiceSuccessPopup
          invoiceData={invoiceConfirmModalProps.invoiceData}
          close={() => {
            setShowInvoiceSucessModal(false)
            setInvoiceConfirmModalProps({
              invoiceData: undefined,
              open: false
            })

            document.dispatchEvent(new Event('gosite-invoiceCreated'))
          }}
          me={me}
          showAdBanner={false}
        />
      )}
    </>
  )
}

const MessagePopupContainer = ({ open, setOpen, flow }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const me = useSelector((state) => state.auth.me)
  const authApiError = useSelector((state) => state.auth.apiError)
  const msgApiError = useSelector((state) => state.messenger.msgApiError)

  const verifyMobileFunc = (payload) => {
    return dispatch.messenger.verifyMobile(payload)
  }

  const onRequestOtpOnCallFunc = (payload) => {
    return dispatch.messenger.onRequestOtpOnCall(payload)
  }

  const smsVerifyOtpFunc = (payload) => {
    return dispatch.auth.smsVerifyOtp(payload)
  }

  const handleSubmitOtp = async ({ payload }) => {
    payload.enableTwilio = true
    const res = await smsVerifyOtpFunc(payload)

    if (!res) {
      return false
    }

    // refetch me to update redux
    await dispatch.auth.getMe()

    return true
  }

  return (
    <SendMessagePopup
      flow={flow}
      open={open}
      onClose={() => setOpen(false)}
      onSendMsgSuccess={() => {
        setOpen(false)
        dispatch.actionCenter.getActionCenter()
      }}
      onRequestOtp={verifyMobileFunc}
      meData={me}
      apiError={msgApiError || authApiError || ''}
      verifyOtp={handleSubmitOtp}
      onRequestOtpOnCall={onRequestOtpOnCallFunc}
      securityText={t(
        'home.for-security-reasons-youll-need-to-set-up-2-factor-authentication-to-use-gosites-sms-capabilities'
      )}
      title={t('home.first-verify-your-account')}
    />
  )
}

const SendBookingPopupContainer = ({ open, setOpen, flow }) => {
  const dispatch = useDispatch()

  return (
    <SendBookingLinkPopup
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      onSendBookingLink={async (data) => {
        return dispatch.messenger.sendBookingLink({
          ...data,
          created_by_flow: flow
        })
      }}
      disableContactsImport
    />
  )
}

const AddContactPopupContainer = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const allGroups = useSelector((state) => state.contact.allGroups)
  const createdGroup = useSelector((state) => state.contact.createdGroup)
  const contactApiError = useSelector((state) => state.contact.apiError)
  const me = useSelector((state) => state.auth.me)

  const [showCreateGroupPopup, setShowCreateGroupPopup] = useState(false)

  const saveGroup = async ({ values }) => {
    await dispatch.contact.createGroups(values)
    await dispatch.contact.fetchGroupsWithoutPaging()
    setShowCreateGroupPopup(false)
  }

  const addContact = async ({ values }) => {
    await dispatch.contact.addContact(values)

    if (!contactApiError) {
      setOpen(false)
      segmentEventTrack({
        event: 'contact_added',
        properties: {
          role:
            me && me.role_id && me.role_id.role_name && me.role_id.role_name && me.role_id.role_name === 'trial'
              ? 'free-trial'
              : me.role_id.role_name,
          has_email: !!values.email,
          has_phone: !!values.mobile,
          has_address: !!(values.address || values.address2)
        }
      })
    }
  }

  return (
    <>
      {/* <div>showCreateGroupPopup: {JSON.stringify(showCreateGroupPopup)}</div> */}
      <AddContactPopup
        id={'modalAddContact'}
        groups={allGroups}
        open={open}
        addGroups={saveGroup}
        newGroup={createdGroup}
        openCreatePopUp={() => {
          console.log(showCreateGroupPopup)
          setShowCreateGroupPopup(!showCreateGroupPopup)
        }}
        close={() => {
          setOpen(false)
          setShowCreateGroupPopup(false)
        }}
        onSubmit={addContact}
      />
      {showCreateGroupPopup && (
        <CreateGroupPopup
          open={showCreateGroupPopup}
          setOpen={setShowCreateGroupPopup}
          onSubmit={async ({ values }) => {
            await dispatch.contact.createGroups(values)
            await dispatch.contact.fetchGroupsWithoutPaging()
            setShowCreateGroupPopup(false)
          }}
        />
      )}
    </>
  )
}

const ReviewEstimatePopupContainer = () => {
  const { t } = useTranslation()
  const me = useSelector((state) => state.auth.me)
  const dispatch = useDispatch()

  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [reviewEstimatePopupState, setReviewEstimatePopupState] = useState({
    open: false,
    estimate: undefined
  })

  const showReviewEstimateDialog = (event) => {
    const { contact, items, expiredAt, sendTime, sendVia, attachments } = event.detail
    if (attachments) {
      setReviewEstimatePopupState({
        open: true,
        estimate: { contact, items, expiredAt, sendTime, sendVia, attachments }
      })
    } else {
      setReviewEstimatePopupState({
        open: true,
        estimate: { contact, items, expiredAt, sendTime, sendVia }
      })
    }
  }

  useEffect(() => {
    document.addEventListener('gosite-showReveiwEstimatePopup', showReviewEstimateDialog)

    return () => {
      document.removeEventListener('gosite-showReveiwEstimatePopup', showReviewEstimateDialog)
    }
  }, [])

  const destination = (() => {
    const { sendVia, contact: { email, phone } = {} } = reviewEstimatePopupState.estimate || {}

    if (sendVia === 'both' || sendVia === 'email') {
      return email
    }

    return phone
  })()

  return (
    <>
      {reviewEstimatePopupState.estimate && (
        <ReviewEstimatePopup
          open={reviewEstimatePopupState.open}
          setOpen={(open) => setReviewEstimatePopupState({ open })}
          estimate={reviewEstimatePopupState.estimate}
          title={t('estimates.review-and-send-estimate')}
          businessName={me.bussiness_name || me.account_settings.bussiness_name}
          onCancel={() => setReviewEstimatePopupState({ open: false })}
          onConfirm={async (message) => {
            const res = await dispatch.estimates.postEstimate({
              ...reviewEstimatePopupState.estimate,
              message
            })

            if (!res) {
              return
            }

            setReviewEstimatePopupState({ ...reviewEstimatePopupState, open: false })
            setSuccessPopupOpen(true)
          }}
          onEdit={() => {
            setReviewEstimatePopupState({ ...reviewEstimatePopupState, open: false })
            document.dispatchEvent(
              new CustomEvent('gosite-showCreateEstimatePopup', {
                detail: {
                  estimate: reviewEstimatePopupState.estimate
                }
              })
            )
          }}
        />
      )}

      <EstimateSuccessPopup
        open={successPopupOpen}
        setOpen={(open) => setSuccessPopupOpen(open)}
        title={t('estimates.estimate-sent-successfully')}
        description={
          <Box
            sx={{
              p: {
                textAlign: 'center'
              }
            }}>
            <p>
              {t('estimates.your-customer')} {destination} {t('estimates.should-receive-the-updated-estimate-shortly')}
            </p>
            <p>
              {t(
                'estimates.once-the-estimate-has-been-accepted-the-status-will-update-automatically-in-your-gosite-dashboard'
              )}
            </p>
          </Box>
        }
        confirmButtonText={t('estimates.back-to-dashboard')}
        onConfirm={() => {
          setSuccessPopupOpen(false)
          setReviewEstimatePopupState({ estimate: undefined, open: false })

          document.dispatchEvent(new Event('gosite-estimateCreated'))

          if (window.location.pathname.split('/').pop() !== 'estimates') {
            history.replace(`/${me.user_unique_id}/estimates`)
          }
        }}
      />
    </>
  )
}

const NewEstimatePopupContainer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [createEstimateDialogOpen, setCreateEstimateDialogOpen] = useState(false)
  const [defaultEstimate, setDefaultEstimate] = useState(undefined)

  const me = useSelector((state) => state.auth.me)
  const uploadingAttachments = useSelector((state) => state.estimates.uploadingAttachments)
  const uploadedAttachments = useSelector((state) => state.estimates.uploadedAttachments)

  const showCreateEstimateDialog = (event) => {
    setCreateEstimateDialogOpen(true)

    const estimate = _.get(event, 'detail.estimate', undefined)
    if (estimate) {
      setDefaultEstimate(event.detail.estimate)
    }
  }

  useEffect(() => {
    document.addEventListener('gosite-showCreateEstimatePopup', showCreateEstimateDialog)

    return () => {
      document.removeEventListener('gosite-showCreateEstimatePopup', showCreateEstimateDialog)
    }
  }, [])

  return (
    <EditEstimatePopup
      smsEnabled={
        _.get(me, 'account_settings.twilio.length', 0) > 0 || _.get(me, 'role_id.role_name', undefined) === 'trial'
      }
      open={createEstimateDialogOpen}
      title={t('estimates.new-estimate-0')}
      confirmButtonText={t('estimates.review-estimate')}
      defaultEstimate={defaultEstimate}
      uploadingAttachments={uploadingAttachments}
      uploadedAttachments={uploadedAttachments}
      onCancelUploadingEstimateAttachment={(fileName) => {
        dispatch.estimates.onCancelUploadingEstimateAttachment(fileName)
      }}
      onClearUploadingEstimateAttachments={() => {
        dispatch.estimates.onClearUploadingEstimateAttachments()
      }}
      onClearUploadedEstimateAttachments={() => {
        dispatch.estimates.onClearUploadedEstimateAttachments()
      }}
      onRemoveUploadedEstimateAttachment={(fileName) => {
        dispatch.estimates.onRemoveUploadedEstimateAttachment(fileName)
      }}
      uploadEstimateAttachments={(files) => {
        dispatch.estimates.uploadEstimateAttachments(files)
      }}
      onSetUploadedEstimateAttachments={(attachments) => {
        dispatch.estimates.onSetUploadedEstimateAttachments(attachments)
      }}
      onCreateNewContact={async ({ phone, email, name }) => {
        const { data: contact } = await dispatch.payment.savePrivateContact({
          email,
          mobile: phone,
          from_messenger: false,
          groupId: 0,
          is_public: true,
          query: {
            exclude_group: 1
          }
        })

        return contact
      }}
      onSearchContact={async (searchText) => {
        const contacts = await dispatch.contact.autosuggestContacts({
          nopaging: true,
          status: 'active',
          excludeNullGroup: true,
          search: searchText || ''
        })

        return contacts || []
      }}
      onSearchService={async (searchText) => {
        const res = await dispatch.services.getServices({
          nopaging: true,
          search: searchText || ''
        })

        if (!res) {
          return []
        }

        const { data: services } = res
        return services.map(({ _id, title, price }) => ({ name: title, price, id: _id }))
      }}
      onCancel={() => {
        setCreateEstimateDialogOpen(false)
        setDefaultEstimate(undefined)
      }}
      onConfirm={({ contact, sendTime, sendVia, expiredAt, items, attachments }) => {
        document.dispatchEvent(
          new CustomEvent('gosite-showReveiwEstimatePopup', {
            detail: {
              contact,
              sendTime,
              sendVia,
              expiredAt,
              items,
              attachments
            }
          })
        )
        console.log({
          contact,
          sendTime,
          sendVia,
          expiredAt,
          items,
          attachments
        })

        setCreateEstimateDialogOpen(false)
        setDefaultEstimate(undefined)
      }}
    />
  )
}

const UpdateEstimatePopupContainer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const me = useSelector((state) => state.auth.me)
  const uploadingAttachments = useSelector((state) => state.estimates.uploadingAttachments)
  const uploadedAttachments = useSelector((state) => state.estimates.uploadedAttachments)

  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [estimate, setEstimate] = useState(undefined)
  const [updatedEstimate, setUpdatedEstimate] = useState(undefined)

  const showCreateEstimateDialog = (event) => {
    console.log(event.detail.estimate)
    setEstimate(event.detail.estimate)
  }

  useEffect(() => {
    document.addEventListener('gosite-showUpdateEstimatePopup', showCreateEstimateDialog)

    return () => {
      document.removeEventListener('gosite-showUpdateEstimatePopup', showCreateEstimateDialog)
    }
  }, [])

  return (
    <>
      {estimate && (
        <EditEstimatePopup
          smsEnabled={
            _.get(me, 'account_settings.twilio.length', 0) > 0 || _.get(me, 'role_id.role_name', undefined) === 'trial'
          }
          open={estimate !== undefined}
          title={t('estimates.update-estimate-0-0', {
            0: estimate.displayId
          })}
          defaultEstimate={estimate}
          uploadingAttachments={uploadingAttachments}
          uploadedAttachments={uploadedAttachments}
          onCancelUploadingEstimateAttachment={(fileName) => {
            dispatch.estimates.onCancelUploadingEstimateAttachment(fileName)
          }}
          onClearUploadingEstimateAttachments={() => {
            dispatch.estimates.onClearUploadingEstimateAttachments()
          }}
          onClearUploadedEstimateAttachments={() => {
            dispatch.estimates.onClearUploadedEstimateAttachments()
          }}
          onRemoveUploadedEstimateAttachment={(fileName) => {
            dispatch.estimates.onRemoveUploadedEstimateAttachment(fileName)
          }}
          uploadEstimateAttachments={(files) => {
            dispatch.estimates.uploadEstimateAttachments(files)
          }}
          onSetUploadedEstimateAttachments={(attachments) => {
            dispatch.estimates.onSetUploadedEstimateAttachments(attachments)
          }}
          confirmButtonText={t('estimates.update-estimate-0')}
          onSearchContact={async (searchText) => {
            const contacts = await dispatch.contact.autosuggestContacts({
              nopaging: true,
              status: 'active',
              excludeNullGroup: true,
              search: searchText || ''
            })

            return contacts || []
          }}
          onCreateNewContact={async ({ phone, email, name }) => {
            const { data: contact } = await dispatch.payment.savePrivateContact({
              email,
              mobile: phone,
              from_messenger: false,
              groupId: 0,
              is_public: true,
              query: {
                exclude_group: 1
              }
            })

            return contact
          }}
          onSearchService={async (searchText) => {
            const res = await dispatch.services.getServices({
              nopaging: true,
              search: searchText || ''
            })

            if (!res) {
              return []
            }

            const { data: services } = res
            return services.map(({ _id, title, price }) => ({ name: title, price, id: _id }))
          }}
          onCancel={() => {
            setEstimate(undefined)
          }}
          onConfirm={async ({ contact, sendTime, sendVia, expiredAt, items, attachments }) => {
            const res = await dispatch.estimates.patchEstimate({
              estimateId: estimate._id,
              body: {
                contact,
                sendTime,
                sendVia,
                expiredAt,
                items,
                message: estimate.message,
                attachments
              }
            })

            if (!res) {
              return
            }

            setUpdatedEstimate({
              ...estimate,
              contact,
              sendTime,
              sendVia,
              expiredAt,
              items,
              attachments
            })

            setEstimate(undefined)
            setSuccessPopupOpen(true)
          }}
        />
      )}
      <EstimateSuccessPopup
        open={successPopupOpen}
        setOpen={(open) => setSuccessPopupOpen(open)}
        title={t('estimates.updated-estimate-sent-successfully')}
        description={
          <Box>
            <p>
              {t('estimates.your-customer')}{' '}
              {_.get(updatedEstimate, 'email', '') || _.get(updatedEstimate, 'phone', '')}{' '}
              {t('estimates.should-receive-the-updated-estimate-shortly')}
            </p>
            <p>
              {t(
                'estimates.once-the-estimate-has-been-accepted-the-status-will-update-automatically-in-your-gosite-dashboard'
              )}
            </p>
          </Box>
        }
        confirmButtonText={t('estimates.back-to-dashboard')}
        onConfirm={() => {
          dispatch.estimates.getEstimateHistories({
            estimateId: updatedEstimate._id,
            query: { sort: { createdAt: 'desc' } }
          })
          dispatch.estimates.getEstimate(updatedEstimate._id)

          setUpdatedEstimate(undefined)
          setSuccessPopupOpen(false)

          document.dispatchEvent(new Event('gosite-estimateUpdated'))

          if (window.location.pathname.split('/').pop() !== 'estimates') {
            history.replace(`/${me.user_unique_id}/estimates`)
          }
        }}
      />
    </>
  )
}

const CreatePolicyPopupContainer = () => {
  const [createPolicyPopupState, setCreatePolicyPopupState] = useState(false)
  const [applicationSubmissionId, setapplicationSubmissionId] = useState('')

  const createPolicyPopup = (event) => {
    setCreatePolicyPopupState(true)
    setapplicationSubmissionId(event.detail.applicationSubmissionId)
  }

  useEffect(() => {
    document.addEventListener('gosite-createPolicyPopup', createPolicyPopup)

    return () => {
      document.removeEventListener('gosite-createPolicyPopup', createPolicyPopup)
    }
  }, [])

  return (
    <CreatePolicyPopup
      applicationSubmissionId={applicationSubmissionId}
      open={createPolicyPopupState}
      setOpen={setCreatePolicyPopupState}
    />
  )
}

const DeleteEstimatePopupContainer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [deleteEstimatePopupState, setDeleteEstimatePopupState] = useState(undefined)

  const showDeleteEstimatePopup = (event) => {
    const { estimateId, message } = event.detail
    setDeleteEstimatePopupState({
      estimateId,
      message
    })

    console.log(event.detail, deleteEstimatePopupState)
  }

  useEffect(() => {
    document.addEventListener('gosite-showDeleteEstimatePopup', showDeleteEstimatePopup)

    return () => {
      document.removeEventListener('gosite-showDeleteEstimatePopup', showDeleteEstimatePopup)
    }
  }, [])

  return (
    <EstimateErrorPopup
      open={deleteEstimatePopupState !== undefined}
      setOpen={() => setDeleteEstimatePopupState(undefined)}
      title={_.get(deleteEstimatePopupState, 'message', '')}
      confirmButtonText={t('estimates.delete-0')}
      onConfirm={async () => {
        const res = await dispatch.estimates.deleteEstimate({ estimateId: deleteEstimatePopupState.estimateId })
        if (!res) {
          return
        }

        document.dispatchEvent(
          new CustomEvent('gosite-estimateDeleted', {
            detail: {
              estimateId: deleteEstimatePopupState.estimateId
            }
          })
        )

        setDeleteEstimatePopupState(undefined)
      }}
      cancelButtonText={t('estimates.cancel')}
      onCancel={() => {
        setDeleteEstimatePopupState(undefined)
      }}
    />
  )
}

export const DialogListener = () => {
  const dispatch = useDispatch()
  const permissions = useIsPermitted()

  const {
    isContactCreatePermitted,
    isMessengerSendPermitted,
    isReviewCreatePermitted,
    isPaymentPermitted,
    isBookingPermitted
  } = permissions

  const [sendMessagePopupState, setSendMessagePopupState] = useState({
    open: false,
    triggerBy: undefined
  })
  const [sendInvoicePopupState, setSendInvoicePopupState] = useState({
    open: false,
    triggerBy: undefined,
    defaultInvoice: undefined
  })
  const [sendReviewRequestPopupState, setSendReviewRequestPopupState] = useState({
    open: false,
    triggerBy: undefined
  })
  const [sendBookingPopupState, setSendBookingPopupState] = useState({
    open: false,
    triggerBy: undefined
  })
  const [addContactPopupState, setAddContactPopupState] = useState({
    open: false,
    triggerBy: undefined
  })

  const me = useSelector((state) => state.auth.me)

  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
    }
  }, [])

  const onBentoEvent = (event) => {
    const message = _.get(event, 'detail.message', undefined)

    const newState = {
      open: true,
      triggerBy: 'Bento'
    }

    if (message === 'send-a-message') {
      setSendMessagePopupState(newState)
      return
    }

    if (message === 'send-a-review-request') {
      setSendReviewRequestPopupState(newState)
      return
    }

    if (message === 'send-an-invoice') {
      setSendInvoicePopupState(newState)
      return
    }

    if (message === 'send-a-booking') {
      setSendBookingPopupState(newState)
      return
    }

    if (message === 'add-a-contact') {
      setAddContactPopupState(newState)
    }
  }

  const showSendInvoicePopupFromEstimate = (event) => {
    const { estimate } = event.detail
    const { contact } = estimate
    const [firstName, ...lastName] = _.get(contact, 'name', '').split(' ')

    const businessName = me.bussiness_name ? me.bussiness_name : me.account_settings.bussiness_name
    const monthString = moment().format('MMM YYYY')

    console.log(`${businessName}: ${monthString}`)

    setSendInvoicePopupState({
      open: true,
      triggerBy: 'estimate',
      defaultInvoice: {
        estimateId: estimate._id,
        customer_id: contact.id,
        customer_first_name: firstName,
        customer_last_name: lastName.join(' '),
        customer_email: contact.email,
        customer_phone: contact.phone,
        invoice_amount: estimate.items.reduce((prev, curr) => prev + curr.rate * curr.quantity, 0) / 100,
        send_email: Boolean(contact.email),
        send_sms: Boolean(contact.phone),
        send_type: 'immediatly',
        invoice_id: '',
        invoice_number: '',
        invoice_title: `${businessName}: ${monthString}`,
        invoice_items: estimate.items.map((item, idx) => ({
          service_description: '',
          service_id: item.serviceId || '',
          service_name: item.name,
          service_price: `${item.rate / 100}`,
          service_price_total: (item.rate * item.quantity) / 100,
          service_quantity: item.quantity,
          service_status: 'open',
          sort_order: idx + 1
        })),
        invoice_wisetack_transaction: estimate.wisetackTransaction,
        invoice_description: '',
        invoice_due: undefined,
        scheduled_date: undefined,
        customer_address: '',
        customer_address2: '',
        customer_city: '',
        customer_zip: ''
      }
    })
  }

  useEffect(() => {
    document.addEventListener('bento-buttonClicked', onBentoEvent)
    document.addEventListener('gosite-showSendInvoicePopupFromEstimate', showSendInvoicePopupFromEstimate)

    return () => {
      document.removeEventListener('bento-buttonClicked', onBentoEvent)
      document.removeEventListener('gosite-showSendInvoicePopupFromEstimate', showSendInvoicePopupFromEstimate)
    }
  }, [])

  const enableSendInvoice =
    Boolean(
      _.get(me, 'account_settings.payment.merchant_id') || _.get(me, 'account_settings.payment.isInvoiceUnlocked')
    ) && isPaymentPermitted

  return (
    <>
      {isMessengerSendPermitted && (
        <MessagePopupContainer
          flow={sendMessagePopupState.triggerBy}
          open={sendMessagePopupState.open}
          setOpen={(open) =>
            setSendMessagePopupState({
              ...sendMessagePopupState,
              open
            })
          }
        />
      )}
      {isReviewCreatePermitted && (
        <ReviewRequestPopup
          flow={sendReviewRequestPopupState.triggerBy}
          open={sendReviewRequestPopupState.open}
          onClose={() => {
            setSendReviewRequestPopupState({
              ...sendReviewRequestPopupState,
              open: false
            })
          }}
          onSubmitSuccess={() => {
            dispatch.actionCenter.getActionCenter()
          }}
        />
      )}
      {enableSendInvoice && (
        <SendInvoicePopupContainer
          open={sendInvoicePopupState.open}
          setOpen={(open) =>
            setSendInvoicePopupState({
              ...sendInvoicePopupState,
              open
            })
          }
          defaultInvoice={sendInvoicePopupState.defaultInvoice}
        />
      )}
      {isBookingPermitted && (
        <SendBookingPopupContainer
          flow={sendBookingPopupState.triggerBy}
          open={sendBookingPopupState.open}
          setOpen={(open) =>
            setSendBookingPopupState({
              ...sendBookingPopupState,
              open
            })
          }
        />
      )}
      {isContactCreatePermitted && (
        <AddContactPopupContainer
          open={addContactPopupState.open}
          setOpen={(open) =>
            setAddContactPopupState({
              ...addContactPopupState,
              open
            })
          }
        />
      )}

      <NewEstimatePopupContainer />
      <UpdateEstimatePopupContainer />
      <RenewEstimatePopupContainer />
      <ReviewEstimatePopupContainer />
      <DeleteEstimatePopupContainer />
      <CreatePolicyPopupContainer />
    </>
  )
}
